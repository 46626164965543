<template>
  <div class="container">
    <div class="header-do" v-show="selectedFolderIds.length==0">
        <el-button type="primary" class="createCgon" @click="createTask(0)" size="small">
          <img src="../../assets/image/add.png" alt="" class="btn-img addtask">
          <span>开始识别</span> 
        </el-button>
    </div>
    <div class="header-do checkeone" v-show="selectedFolderIds.length>0">
        <el-button class="createCgon" @click="commandClick('',3)" size="small" style="font-weight: 600">
          <img src="../../assets/image/word.png" alt="" class="btn-img">
          <span>导出Word</span> 
        </el-button>
        <el-button class="createCgon" @click="commandClick('',2)" v-if="!isWordExport" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/execl.png" alt="" class="btn-img">
          <span>导出Excel</span>
        </el-button>
        <el-button class="createCgon" @click="commandClick('',1)" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/pdf.png" alt="" class="btn-img">
          <span>导出PDF</span>
        </el-button>
        <el-button class="createCgon" @click="deleteSelected('')" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/delete.png" alt="" class="btn-img">
          <span>删除</span>
        </el-button>
    </div>
    <el-container>
      <el-main>
        <div class="does">
          <div class="createAll" v-if="false">
              <el-select style="width:102px;margin-top: 10px;"
              v-model="pid"
              placeholder="识别类型"
              >
                  <el-option v-for="(val,key,index) in pidTypesMap" :label="key" :value="val" :key="index"></el-option>
              </el-select>
                <div class="type-task" @click="createTask(0)">
                    <span class="taskicon iconfont icon-wenbenshibie"></span>
                    <span>识别</span>
                </div>
                <div class="type-task" @click="createTask(1)">
                    <span class="taskicon iconfont icon-xiangyou"></span>
                    <span>图片->PDF</span>
                </div>
                <div class="type-task" @click="createTask(2)">
                    <span class="taskicon iconfont icon-xiangyou"></span>
                    <span>图片->Word</span>
                </div>
                <div class="type-task" @click="createTask(3)">
                    <span class="taskicon iconfont icon-xiangyou"></span>
                    <span>PDF->Word</span>
                </div>
                <div class="type-task" @click="createTask(4)" v-show="false">
                    <span class="taskicon iconfont icon-xiangyou"></span>
                    <span>截图->PDF</span>
                </div>
            </div>
          <el-row style="margin-top:20px" v-if="false">
              <el-col :span="6">
                  <el-button
                      type="primary"
                      size="small"
                      @click="deleteSelected('')"
                      >批量删除</el-button
                  >
                  <el-dropdown @command="handleCommand" style="margin-left: 15px;">
                    <el-button
                      type="primary"
                      size="small"
                      >批量导出<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown" @click.native="commandClick('')">
                      <el-dropdown-item command="1">PDF</el-dropdown-item>
                      <el-dropdown-item command="2">Excel</el-dropdown-item>
                      <el-dropdown-item command="3">Word</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
              </el-col>
              <el-col :span="5" :offset="1">
                  <el-date-picker
                      v-model="createDate"
                      type="daterange"
                      align="right"
                      value-format="yyyy-MM-dd"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions"
                      ></el-date-picker>
              </el-col>
              <el-col :span="3" :offset="1">
                  <el-select
                  v-model="condition.taskType"
                  placeholder="请选择查询类型"
                  >
                      <el-option label="所有类型" value=""></el-option>
                      <el-option label="识别" value="0"></el-option>
                      <el-option label="图片->PDF" value="1"></el-option>
                      <el-option label="图片->Word" value="2"></el-option>
                      <el-option label="PDF->Word" value="3"></el-option>
                      <el-option label="截图->PDF" value="4"></el-option>
                  </el-select>
              </el-col>
              <el-col :span="3" :offset="1">
                  <el-input v-model="condition.taskName" placeholder="任务名称"></el-input>
              </el-col>
              <el-col :span="2" :offset="1">
                  <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-search"
                      @click="getTaskList"
                      >查询</el-button
                  >
              </el-col>
          </el-row>
          <div style="margin-top:10px" class="candode">
              <div style="margin-left: 34px">
                  <p class="hedat-contnt">我的记录</p>
              </div>
              <div style="width: 17%">
                <el-input class="searchUser sousuoicon" v-model="condition.taskName" placeholder="搜索任务名称" prefix-icon="iconfont icon-sousuo" @keyup.native.enter="getTaskList"></el-input>
              </div>
          </div>
        </div>
        <div class="viewOCR-container viewOCR">
          <div class="corFileList">
            <el-table
              class="tableLimit"
              ref="tableLimit"
             :default-sort = "{prop: 'date'}"
              :data="tabelData"
              height="100%"
              style="width: 100%;height:100%"
              @selection-change="selectAll"
              :row-key="rowKey"
              :expand-row-keys="expandRowKeys"
              @expand-change="expandChange"
              :row-class-name="tableRowClassName"
              @sort-change="sortChange"
            >
              <el-table-column type="selection" width="35" ></el-table-column>
              <!-- 展开部分 -->
              <el-table-column type="expand" width="35" class-name="expandButton">
                <template slot-scope="props">
                  <el-table
                    class="expandLimit"
                    :data="props.row.details"
                    style="width: 100%"
                    v-if="taskFileListVisible"
                  >
                    <el-table-column label="产品名称" prop="productName">
                    </el-table-column>
                    
                    <el-table-column label="文件名称" prop="fileName">
                    </el-table-column>
                    <el-table-column min-width="120"
                      prop="comment"
                      label="备注"
                    >	
                    </el-table-column>
                    
                    <el-table-column
                      label="类型"
                    >	
                    <template slot-scope="scope">
                      <div>
                        <span>{{scope.row.taskType==0?'识别':scope.row.taskType==1?'图片转PDF':scope.row.taskType==2?'图片转Word':scope.row.taskType==3?'PDF转Word':scope.row.taskType==4?'截图->Word':scope.row.taskType==5?'PDF转Excel':scope.row.taskType==6?'PDF拆分':''}}</span>
                      </div>
                    </template>
                    </el-table-column>
                    <el-table-column
                      label="状态"
                    >	
                      <template slot-scope="scope">
                          <div>
                              <span :class="{'failStatus':scope.row.result==0,'finsishStatus':scope.row.result==1,'doingStatus':scope.row.result==2}"></span>
                              <span>{{scope.row.result==0?'识别失败':scope.row.result==1?'识别成功':scope.row.result==2?'识别中':''}}</span>
                          </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="makeTime"
                      label="创建时间"
                    >
                    </el-table-column>
                    <el-table-column label="操作" min-width="140">
                      <template slot-scope="scope">
                        <el-button type="text" size="small" @click="download(scope.row)" v-if="scope.row.taskType!=0&&scope.row.result==1">下载</el-button> 
                        <el-dropdown v-if="scope.row.taskType==0&&scope.row.result==1" @command="handleCommand">
                          <span class="el-dropdown-link">
                            导出<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown" @click.native="commandClick(scope.row)">
                            <el-dropdown-item command="1">PDF</el-dropdown-item>
                            <el-dropdown-item command="2" v-if="scope.row.pid!=1001&&scope.row.pid!=1034">Excel</el-dropdown-item>
                            <el-dropdown-item command="3">Word</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        <el-button type="text" size="small" @click="compaire(scope.row)" v-if="scope.row.taskType==0&&scope.row.pid!=1001&&scope.row.result==1" style="margin-left:10px">校对</el-button>
                        <el-button type="text" size="small" @click="updateRecord(scope.row)" v-if="(scope.row.taskType==0&&scope.row.pid==1001)||scope.row.taskType!=0||scope.row.result!=1" style="margin-left:10px">备注信息</el-button>
                        <el-button type="text" size="small" @click="deleteRecord(scope.row.recordId)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                prop="taskName"
                label="任务名称"
              >
              </el-table-column>
              
              <el-table-column
                prop="taskDescription"
                label="任务描述"
              >
              </el-table-column>
              
              <el-table-column
                prop="makeTime"
                label="创建时间"
                sortable
                min-width="60"
                class-name="makeTime"
              >
              </el-table-column>

              <el-table-column label="操作" min-width="90">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteSelected(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="condition.currentPage"
              :page-sizes="[10, 15, 20, 30, 50, 100]"
              :page-size="condition.pageSize"
              layout="slot, total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
        title="创建识别任务"
        :visible.sync="dialogTaskFileVisible"
        v-if="dialogTaskFileVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="55%"
        height="100%"
        class="ocrTask"
        @close="clearTaskFile"
      >
        <task-file ref="taskFile" :taskType="taskType"></task-file>
    </el-dialog>
    <el-dialog
        title="修改备注信息"
        :visible.sync="dialogUpdateRecordVisible"
        v-if="dialogUpdateRecordVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="55%"
        height="100%"
        class="ocrTask"
        @close="clearUpdateRecord"
      >
        <update-record ref="updateRecord" :recordRow="recordRow"></update-record>
    </el-dialog>
    
    <el-dialog
        title="校对"
        :visible.sync="dialogCompaireFileVisible"
        v-if="dialogCompaireFileVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="90%"
        height="100%"
        class="compaire"
        @close="clearCompaireFile"
      >
        <compaire ref="compaireFile" :compaireRow="compaireRow" :formType="openType"></compaire>
    </el-dialog>
  </div>
</template>

<style>
.el-table .warning-row {
  background: oldlace;
}
.el-table .success-row {
  background: #f0f9eb;
}
</style>

<script type="text/javascript">
// let Base64 = require('js-base64').Base64
import taskFile from '../../components/creatTask'
import updateRecord from '../../components/updateRecord'
import compaire from '../../components/compaire'

export default {
  name: "ViewOCR",
  components: {
    taskFile,
    updateRecord,
    compaire
  },
  data() {
    return {
      dialogTaskFileVisible:false,
      dialogUpdateRecordVisible:false,
      progress: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
      createDate: ["", ""],
      condition: {
        startTime: "",
        endTime: "",
        taskType:0,
        currentPage: 1,
        pageSize: 10,
        taskName:''
      },
      taskType:0,
      pid:1001,
      taskPid:'',
      pidTypesMap:{"通用文字识别":1001,"手写识别":1034,"身份证":2,"银行卡":4,"护照":8,"港澳通行证":1013,"户口本":1002,"外国人永久居留证":53,
      "结婚证":1004,"离婚证":1005,"军官证":11,"出生证":52,"社保卡":1039,"驾驶证":7,"行驶证":5,"VIN码":1,"机动车登记证":62,"机动车合格证":68,"增值税发票":21,
      "出租车票":83,"定额发票":84,"火车票":66,"行程单":82,"电子承兑汇票":1031,"机动车发票":61,"二手车发票":60,"营业执照":41,"开户许可证":1015,"全国各省市房产证":1033},
      total: 0,
      tabelData: [],
      taskFileList:[],
      selectedFolderIds: [],
      loading: false,
      taskFileListVisible:true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      recordRow:'',
      expandRowKeys:[],   //展开行id的数组
      noPushExpand:false,
      exportType:'',
      canMoreExport:false,
      timer:'',
      hasNoRcgon:[],
      dialogCompaireFileVisible:false,
      compaireRow:'',
      openType:'',
      hasNoCanExport:false,
      isWordExport:false   // 是否是文字导出
    };
  },
  mounted() {
    let that = this;
    this.getTaskList()
    this.$bus.on("closeTaskFileRecordDialog", () => {
      this.dialogTaskFileVisible = false;
    });
    this.$bus.on("closeUpdateRecordDialog", () => {
      this.dialogUpdateRecordVisible = false;
    });
    this.$bus.on("closeCompaireDialog", () => {
      this.dialogCompaireFileVisible = false;
    });
    
    this.$bus.on("refreshDataRecord", () => {
      this.dialogTaskFileVisible = false;
      this.dialogUpdateRecordVisible = false;
      this.dialogCompaireFileVisible = false;
      this.noPushExpand=true
      this.getTaskList()
    });
  },
  beforeDestroy() {
    this.$bus.off("closeTaskFileRecordDialog")
    this.$bus.off("closeUpdateRecordDialog")
    this.$bus.off("closeCompaireDialog")
    this.$bus.off("refreshDataRecord")
    if(this.timer){
      clearInterval(this.timer)
      this.timer=null
    }
  },
  watch:{
    hasNoRcgon: {
      handler(val){
        if(val.length>0){
          if(!this.timer){
            this.timer=setInterval(() => {
              this.getTaskList()
            }, 3000);
          }
        }else{
          if(this.timer){
            clearInterval(this.timer)
            this.timer=null
          }
        }
      },
      deep: true
    }
  },
  methods: {
    sortChange(column){
      console.log(column)  //名称排序
      var sortingType = column.order;
      $(".tableLimit th").css({
        fontWeight: 500,
        color: '#313131'
      })
      $(`.tableLimit th.${column.column.className}`).css({
        color:'#000',
        fontWeight:600
      })
      if(!sortingType){
        $(".tableLimit th").css({
          fontWeight: 500,
          color: '#313131'
        })
      }
      // console.log(this.tabelData);
    },
    // 选择导出类型
    handleCommand(command) {
      // pdf:1 ,excel:2 ,word:3
      this.exportType=command
    },
    // 导出 单个|批量
    commandClick(row,command){
      console.log(row,command)
      if(command){
        this.exportType=command
      }
      var param={
        exportType:this.exportType
      }
      if(row){
        param.recordIds=[row.recordId]
      }else{
        if(this.selectedFolderIds.length==0){
          this.$message({
            message:"请选择数据",
            type:'warning'
          })
          this.canMoreExport=false
          return
        }
        var pids=[],num=0
        this.selectedFolderIds.forEach(item=>{
          if(item.details.length>0){
            if(item.details[0].taskType!=0){
              num++
            }else{
              pids.push(item.pid)
            }
          }
        })
        if(num==0){
          var pidDifferent=0
          pids.forEach(item=>{
            if(item!=pids[0]){
              pidDifferent++
            }
          })
          if(pidDifferent!=0){
            this.$message({
              message:"选择的识别任务文件中存在不同类型，不允许批量导出",
              type:"warning"
            }) 
            this.canMoreExport=false
            return
          }else{
            this.canMoreExport=true
          }
        }else{
          this.$message({
            message:"选择的任务文件中存在转换任务，不允许批量导出",
            type:"warning"
          })
          this.canMoreExport=false
          return
        }
        if(this.canMoreExport){
          let recordIds=[]
          this.selectedFolderIds.forEach(item=>{
            if(item.details.length>0){
              item.details.forEach(it=>{
                recordIds.push(it.recordId)
              })
            }
          })
          param.recordIds=recordIds
        }
      }
      console.log(param)
      if(param.recordIds&&param.recordIds.length>0){
        console.log(param)
        this.$ajax.post("/api/web/apiRecord/exportRecord",JSON.stringify(param)).then(res=>{
          if(res.code==200){
            this.exportDown(param.recordIds)
          }
        })
      }
    },
    exportDown(recordIds){
      console.log(this.exportType)
      var formHtml = "<form id='downLoadForm' style='display:none' method='post' action='/api/web/apiRecord/downloadRecordFile'>" + 
      "<input type='hidden' name='recordIds' value='" + JSON.stringify(recordIds) + "'>" +
      "<input type='hidden' name='exportType' value='" + this.exportType + "'>" +
      "</form>";
      var divDom = document.createElement("div");
      divDom.innerHTML = formHtml;
      document.body.appendChild(divDom);
      document.getElementById("downLoadForm").submit();
      document.body.removeChild(divDom);
    },
    compaire(row){
      // let hasTabs=this.$store.state.editableTabs
      // let hasH=hasTabs.filter(item=>{
      //   if(item.name=='校对详情'){
      //     return true
      //   }
      // })
      // if(hasH.length==0){
      //   this.$store.commit("add_tabs",{
      //     name: '校对详情',
      //     content: 'compaire',
      //   })
      //   this.$store.commit('set_active_index','校对详情');
      // }else{
      //   this.$store.commit('set_active_index',hasH[0].name);
      // }
      // let param = {
      //   row:JSON.parse(JSON.stringify(row)),
      //   type:'myrecord',
      // }
      console.log(row)
      this.compaireRow=JSON.parse(JSON.stringify(row))
      this.openType='myrecord'
      this.$nextTick(()=>{
        this.dialogCompaireFileVisible=true
      })
      // this.$store.commit("setCompaireParam",param)
    },
    clearUpdateRecord(){
      this.$refs.updateRecord.clearAll()
    },
    updateRecord(row){
      this.recordRow=row
      this.$nextTick(()=>{
        this.dialogUpdateRecordVisible=true
      })
    },
    expandChange(row, expandedRows){
      if(!row.flag){
        this.getTaskFileList(row)
      }
      row.flag=!row.flag
      if(!this.noPushExpand){
        this.expandRowKeys=[]
        expandedRows.forEach(item=>{
          this.expandRowKeys.push(item.id)
        })
        console.log(this.expandRowKeys)
      }
    },
    createTask(taskType){
      this.taskType=taskType
      // if(taskType==0){
      //   if(this.pid!=''&&this.pid){
      //     this.taskPid=this.pid
      //   }else{
      //     this.$message({message:'请选择证卡类型',type: 'warning'})
      //     return
      //   }
      // }else{
      //   this.taskPid=''
      // }
      this.$nextTick(()=>{
        this.dialogTaskFileVisible=true
      })
    },
    download(row){
      var $a=document.createElement("a");
      $a.href='/api/web/apiRecord/downloadTransitionFile?recordId='+row.recordId
      var evObj=document.createEvent("MouseEvents");
      evObj.initEvent("click",false,false);
      $a.dispatchEvent(evObj);
    },
    selectAll(sels) {
      this.selectedFolderIds = sels;
      console.log(sels)
      var pids=[],num=0
      sels.forEach(item=>{
        if(item.details.length>0){
          if(item.details[0].taskType!=0){
            num++
          }else{
            pids.push(item.pid)
          }
        }
      })
      console.log(pids)
      if(num==0){
        var pidDifferent=0
        pids.forEach(item=>{
          if(item!=pids[0]){
            pidDifferent++
          }
        })
        if(pidDifferent!=0){
          this.$message({
            message:"选择的识别任务文件中存在不同类型，不允许批量导出",
            type:"warning"
          }) 
        }
      }else{
        this.$message({
          message:"选择的任务文件中存在转换任务，不允许批量导出",
          type:"warning"
        })
      }
      this.isWordExport=this.selectedFolderIds.every(item=>item.pid==1001||item.pid==1034)
    },
    // 删除记录
    deleteRecord(id){
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          console.log(this.loading)
          if(this.loading){
            return
          }
          this.loading = true;
          var params={"recordIds": [id]}
          this.$ajax.post("/api/web/apiRecord/deleteRecord",params).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.loading = false;
              this.getTaskList();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
              this.loading = false;
            }
          }).catch((err)=>{
            this.$notify({
              title: "提示",
              message: err,
              type: "error",
              duration: 1500,
            });
            this.loading = false;
          });
      }).catch((err) => {

      })
    },
    // 删除任务
    deleteSelected(id) {
      if(this.selectedFolderIds.length==0&&!id){
        this.$message({message:'未选择数据',type: 'warning'})
        return
      }
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.loading = true;
          var ids=!id?this.selectedFolderIds.map((item) => item.id):[id];
          this.$ajax.post("/api/web/task/deleteTask",{taskIds: ids}).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.loading = false;
              this.getTaskList();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
              this.loading = false;
            }
          });
      }).catch((err) => {

      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    getTaskList() {
      if (this.createDate != null) {
        this.condition.startTime = this.createDate[0];
        this.condition.endTime = this.createDate[1];
      } else {
        this.condition.startTime = "";
        this.condition.endTime = "";
      }
      let formdata=new FormData()
      formdata.append('startTime',this.condition.startTime)
      formdata.append('endTime',this.condition.endTime)
      formdata.append('taskType',this.condition.taskType)
      formdata.append('currentPage',this.condition.currentPage)
      formdata.append('pageSize',this.condition.pageSize)
      formdata.append('taskName',this.condition.taskName)
      //查询用户拥有的记录
      this.$ajax
        .post("/api/web/task/getTaskList", formdata)
        .then((res) => {
          if (res.code == 200) {
            this.hasNoRcgon=[]
            this.tabelData = res.data.content;
            this.total = res.data.totalElements;
            console.log(res.data.content.length)
            res.data.content.forEach(item=>{
              item.flag=false
              item.details=[]
              // if(this.expandRowKeys.length>0){
              //   this.expandRowKeys.forEach(id=>{
              //     if(item.id==id){
              //       this.getTaskFileList(item)
              //     }
              //   })
              // }else{
                this.getTaskFileList(item)
              // }
            })
            this.noPushExpand=false
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    getTaskFileList(row) {
      let formdata=new FormData()
      formdata.append('taskId',row.id)
      this.$ajax
        .post("/api/web/apiRecord/getRecords", formdata)
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach(item=>{
              if(item.result==2){
                this.hasNoRcgon.push(item)
              }
            })
            this.taskFileListVisible=false
            this.$set(row,'details',res.data)
            this.$nextTick(()=>{
              this.taskFileListVisible=true
            })
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    rowKey(row){
      return row.id
    },
    handleSizeChange(pageSize) {
      this.condition.pageSize = pageSize;
      this.progress = {};
      for (let i = 1; i <= pageSize; i++) {
        this.progress[i] = 0;
      }
      this.getTaskList();
    },
    handleCurrentChange(page) {
      this.condition.currentPage = page;
      this.getTaskList();
    },
    dialogClose() {
      this.$refs["upload"].fileList = [];
      this.$refs["upload"].currentUploadFileIndex = 0;
    },
    queryConsultById(id) {
      this.$router.push({ path: "/consult", query: { folderId: id } });
    },
    clearTaskFile(){
      this.$refs.taskFile.clearAll()
    },
    clearCompaireFile(){
      this.$refs.compaireFile.clearAll()
    }
  },
};
</script>
<style type="text/css" scoped>
.compaire >>>.el-dialog {
    height: calc(100% - 8vh);
    margin-top: 5vh!important;
    margin: 0 auto;
}
.compaire >>>.el-dialog__body {
    height: calc(100% - 54px);
}
.searchUser >>>.el-input__inner{
  border-radius: 20px;
}
.btn-img.addtask {
    width: 13px;
    height: 13px;
}
img.btn-img {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}
.checkeone .btn-img{
    width: 17px;
    height: 18px;
}
.createCgon>>>span {
    display: flex;
    align-items: center;
}

.header-do {
    height: 50px;
    border-bottom: 2px solid #eee;
    display: flex;
    align-items: center;
}
.createCgon {
    margin-left: 34px;
    font-size: 13px;
}

.hedat-contnt {
    font-size: 15px;
    font-weight: 600;
}

.candode {
    margin-top: 12px;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 96%;
    height: 40px;
    margin: 0;
    justify-content: space-between;
}
.createAll /deep/.el-input__inner {
    height: 31.6px;
    line-height: 31.6px;
    border: none;
    border-top: 1px solid #ccc;
    border-radius: 0;
}
.createAll /deep/.el-select__caret.el-input__icon.el-icon-arrow-up {
    line-height: 30px;
}
.finsishStatus {
  display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #bebdbd;
    margin-right: 5px;
}
.failStatus {
  display: inline-block;
    width: 5px;
    height: 2px;
    background: rgb(93,177,255);
    margin-right: 5px;
}
.doingStatus {
  display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgb(93,177,255);
    margin-right: 5px;
}
.createAll {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.taskCreate {
    display: flex;
    align-items: center;
}
.type-task {
    width: 97px;
    background: rgb(233 247 250);
    border: 1px solid rgb(59 222 255);
    line-height: 30px;
    font-size: 13px;
    margin-right: 15px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.taskicon{
  font-size: 17px;
  color: rgb(143 134 119);
  margin-right: 4px;
  margin-left: 4px;
}
.el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
    width: 100%;
}
.el-date-editor /deep/.el-range-separator {
    width: 25px;
}
.floderName {
  color: #409eff;
  cursor: pointer;
}
.el-form {
  height: 40px;
}
.progressContainer {
  height: 10px;
  width: 60px;
  border-radius: 10px;
  background-color: #ddd;
  margin-left: 2%;
}
.progress {
  background-color: #1c8de0;
  border-radius: 10px;
  height: 10px;
  line-height: 10px;
}
b {
  color: #fff;
  font-weight: 100;
  font-size: 12px;
  position: absolute;
  left: 20%;
}
.container {
  width: 100%;
  height: 100%;
}
.el-main {
  padding: 0;
  /* height: calc(100% - 40px); */
}
.el-container {
  height: calc(100% - 50px);
}
.viewOCR-header-name:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 5px;
  height: 16px;
  vertical-align: -2px;
  background-color: #2a76cd;
}

.viewOCR-container {
  left: 20px;
  right: 20px;
  bottom: 10px;
  height: calc(100% - 106px);
  margin-top: 8px;
}
.corFileList {
  /* margin-top: 15px; */
  position: relative;
  width: 100%;
  height: calc(100% - 11px);
  overflow: auto;
}

.corFileList .folder_icon {
  vertical-align: -3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(/statics/image/icon/folder_default.png) no-repeat center center;
}

.corFileList .folder_icon.folder_icon_open {
  background: url(/statics/image/icon/folder_open.png) no-repeat center center;
}

.pagination {
  position: fixed;
  left: 0px;
  bottom: 10px;
  width: 100%;
  height: 42px;
}

.pagination .current-tatal {
  float: left;
  font-weight: 400;
}

.pagination .setPageSize {
  float: left;
  margin-left: 10px;
  font-weight: 400;
}

.fixedbox {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -200px;
  width: 400px;
  height: 180px;
  text-align: center;
  line-height: 180px;
  background: #fff;
  font-size: 20px;
  border-radius: 3px;
}
span.regonType {
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 24px;
}
</style>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2a76cd;
  color: #fff;
}

.viewOCR .el-card__header {
  padding: 0px 20px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 900;
}

.viewOCR .el-table__header-wrapper {
  border: 1px solid #dbdcdc;
  font-size: 14px;
  font-weight: 900;
  color: rgba(60, 68, 85, 0.7);
}

.viewOCR .el-table__header-wrapper th {
  height: 50px;
  background: #f3f4f6;
  border-bottom: none;
}

.viewOCR .el-table__body-wrapper {
  font-size: 14px;
  color: #3c4455;
}

.viewOCR .el-table__body-wrapper .el-table__row--level-1 td {
  height: 42px;
  padding: 0px;
  border: none;
}

.viewOCR .el-table__body-wrapper .el-table__row--level-0 td {
  height: 42px;
  padding: 0px;
  border-top: 1px solid #ebeef5;
}

.viewOCR .el-table__body-wrapper .el-table__row--level-0 .el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;
}

.viewOCR .el-dialog__body {
  padding: 10px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

.viewOCR .el-dialog__header .el-dialog__title {
  font-size: 16px;
  font-weight: 900;
}

.viewOCR .el-pagination {
  margin-top: 10px;
  margin-right: 10px;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  text-align: right;
}

.viewOCR .el-pagination__sizes {
  float: left;
  margin: 0 10px 0 5px;
  font-weight: 400;
  color: #606266;
}

.block {
  margin-left: 10%;
}
.el-table /deep/.cell{
  text-emphasis:none;
  overflow: inherit
}
.el-table .cell span {
  word-wrap: break-word;
  word-break: break-all;
}

.el-date-editor .el-range-separator {
  width: 8% !important;
}
.container {
  /* min-width: 1300px; */
  /* overflow: auto; */
}
.container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.el-container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.uplaofile >>>.el-dialog{
  margin-top: 7vh!important;
  height: 95%;
}

.uplaofile >>>.el-dialog__body {
    height: calc(100% - 49px);
    overflow: hidden;
}
.uplaofile >>>.uploadFolder {
    height: calc(100% - 4%);
}
.uplaofile >>>.uploadFolder-form {
    margin-top: 3%;
    height: calc(100% - 50px);
    overflow: auto;
}
.tableLimit >>>.el-table__body-wrapper {
    height: calc(100% - 58px)!important;
}

.tableLimit >>>th{
  font-weight: 500;
  color: #313131;
}
.footer {
    margin-top: 10px;
}
/* .ocrDeatil >>>.el-dialog{
  margin-top: 3vh!important;
} */

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
  margin-left: 2px;
}
</style>
