<template>
    <div class="bing-container">
        <div class="content">
            <!-- <div class="rightbtn" @click="igoreBing">跳过</div> -->
            <div class="contentce">
                <div class="notice-title">
                    <h2 class="content-title">绑定手机</h2>
                    <p class="content-descript"></p>
                </div>
                <el-form ref="form" class="bind-form" label-width="80px">
                    <div class="writeCode">
                        <el-input v-model="phone" placeholder="手机号" @input = "phone = phone.replace(/[^0-9]/g,'')" clearable class="phone input-item"></el-input>
                        <span class="phoneAgo">+86</span>
                    </div>
                    <div class="writeCode">
                        <el-input v-model="code" placeholder="验证码" @input = "phone = phone.replace(/[^0-9]/g,'')" class="setCode input-item" ></el-input>
                        <span @click="getCode" class="sendCode" :class="{'hasSend':hasSend}">{{codeMsg}}</span>
                    </div>
                </el-form>
                <el-button class="bindSure" type="primary" @click="onSubmit">{{btnName}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name:"bind",
    props:["type"],
    data () {
      return {
        loading:false,
        capsTooltip: false,
        phone: '',
        code:'',
        hasSend:false,
        codeMsg:'获取验证码',
        btnName:'绑定并登录',
        hasBinding:0
      }
    },
    mounted () {
        if(this.type=="change"){
            this.btnName="确定"
        }else{
            this.btnName="绑定并登录"
            document.querySelector(".el-dialog__headerbtn").innerHTML="跳过"
        }

        // if(this.$utils.getSessionStorage("token")){
        //     if(this.hasBinding){
        //         this.$router.replace({path: "/index",});
        //     }
        // } else if(this.$store.state.loginParam.code){
        //     //如果只是有code
        //     let _self = this;
        //     let formData=new FormData()
        //     formData.append('code',this.$store.state.loginParam.code)
        //     formData.append('state',this.$store.state.loginParam.state)
        //     //发请求，用code换token
        //     this.$ajax.post("/api/web/login/weChatCallback",formData).then(res=>{
        //         if(res.code==200){
        //             this.$utils.setSessionStorage('token', res.data.accessToken)
        //             this.hasBinding=res.data.binding
        //             if(this.hasBinding){
        //                 this.$router.replace({path: "/index",});
        //             }
        //         }
        //     })
        // }
    },
    methods: {
        igoreBing(done){  // 跳过绑定
            this.$router.replace({path: "/index"});
        },
        getCode(){
            if(this.hasSend||this.loading){
                return
            }
            if(!this.phone){
                this.$message({
                    message:"请输入手机号码",
                    type:'warning'
                })
                return
            }
            var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
            if(!reg.test(this.phone)){
                this.$message({
                    message:"请输入格式正确的手机号码",
                    type:'warning'
                })
                return
            }
            this.loading=true
            let fromData=new FormData()
            fromData.append('phoneNumber',this.phone)
            this.$ajax.post("/api/web/login/getVerificationCode",fromData).then(res=>{
                this.loading=false
                if(res.code==200){
                    let aMinute=59
                    let minueGo=setInterval(()=>{
                        this.codeMsg=aMinute+'秒再获取'
                        this.hasSend=true
                        if(aMinute>0){
                            aMinute--
                        }else{
                            clearInterval(minueGo)
                            minueGo=null
                            this.hasSend=false
                            this.codeMsg='获取验证码'
                        }
                    },1000)
                }else{
                    this.$notify({title: '提示', message: res.msg, type: 'error', duration: 1500})
                }
            }).catch((e) => {
                this.$notify({title: '提示', message: '连接服务器异常!', type: 'error', duration: 1500})
            })
        },
        checkCapslock ({shiftKey, key} = {}) {
            if (key && key.length === 1) {
            if (shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')) {
                this.capsTooltip = true
            } else {
                this.capsTooltip = false
            }
            }
            if (key === 'CapsLock' && this.capsTooltip === true) {
            this.capsTooltip = false
            }
        },
        onSubmit () {
            if(!this.phone){
                this.$message({
                    message:"请输入手机号码",
                    type:'warning'
                })
                return
            }
            var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
            if(!reg.test(this.phone)){
                this.$message({
                    message:"请输入格式正确的手机号码",
                    type:'warning'
                })
                return
            }
            if(!this.code){
                this.$message({
                    message:"请输入验证码",
                    type:'warning'
                })
                return
            }
            let formData=new FormData()
            formData.append('phoneNumber',this.phone)
            formData.append('vcCode',this.code)
            this.$ajax.post('/api/web/user/bindingPhoneNumber', formData).then((res) => {
                if (res.code == 200) {
                    this.$notify({title: '提示', message: '绑定成功', type: 'success', duration: 1500})
                    this.$bus.emit("closeBindDialog")
                    this.$router.replace({path: '/index'})
                } else {
                    this.$notify({title: '提示', message: res.msg, type: 'error', duration: 1500})
                }
            }).catch((e) => {
                this.$notify({title: '提示', message: '连接服务器异常!', type: 'error', duration: 1500})
            })
        },
        clearAll(){
            this.loading= false
            this.capsTooltip=false
            this.phone= ''
            this.code=''
            this.hasSend=false
            this.codeMsg='获取验证码'
            this.btnName='绑定并登录'    
        }
    }
  }
</script>

<style scoped> 
/* .bing-container {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
}
.content {
    width: 44%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgb(240,242,245);
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 27%);
    box-sizing: border-box;
    padding: 0 20px 20px;
}
.rightbtn {
    width: 100%;
    padding: 10px 0 10px;
    text-align: right;
} */
.notice-title {
    text-align: center;
}
    .input-item {
        display: block;
        width: 72%;
        margin: 0 auto;
        margin-bottom: 19px;
    }

    .iconfont.icon-chenggong.green {
        color: rgb(58,193,126);
        line-height: 36px;
    }
    .input-item.item-do {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-bottom: 10px;
    }
    .content-descript {
        padding-top: 3px;
        font-size: 13px;
        color: #aaa;
    }
    .el-form.bind-form {
        padding: 65px 0 20px 0;
    }
    .sendCode {
        position: absolute;
        right: 16%;
        top: 10px;
        cursor: pointer;
        font-size: 13px;
        color: #484848;
    }
    .hasSend{
        color: #409EFF;
    }
    .phone input {
        padding: 0 40px;
    }
    .writeCode {
    position: relative;
}
span.phoneAgo {
    position: absolute;
    left: 15%;
    top: 12px;
    font-size: 14px;
    color: #000;
}
.bind-form /deep/.el-input__inner {
    border: none;
    border-bottom: 1px solid #DCDFE6;
    border-radius: 0;
    padding: 0;
    background: rgb(240,242,245);
    color: #000;
}
.phone /deep/.el-input__inner{
    padding-left: 34px;
}
.bindSure{
    width:72%;
    margin: 0 auto;
    display: block;
    margin-bottom:30px;
}
</style>

