<template>
  <div class="container">
    <div class="header-do" v-show="selectedFolderIds.length==0" v-if="folderList">
    </div>
    <div class="header-do checkeone" v-show="selectedFolderIds.length>0" v-if="folderList">
        <el-button class="createCgon" @click="commandClick('',3)" size="small" style="font-weight: 600">
          <img src="../../assets/image/word.png" alt="" class="btn-img">
          <span>导出Word</span> 
        </el-button>
        <el-button class="createCgon" @click="commandClick('',2)" v-if="!isWordExport" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/execl.png" alt="" class="btn-img">
          <span>导出Excel</span>
        </el-button>
        <el-button class="createCgon" @click="commandClick('',1)" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/pdf.png" alt="" class="btn-img">
          <span>导出PDF</span>
        </el-button>
        <el-button class="createCgon" @click="deleteSelected('')" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/delete.png" alt="" class="btn-img">
          <span>删除</span>
        </el-button>
    </div>
    <div v-if="folderList" class="heights">
      <el-container>
        <el-main>
          <div class="does">
            <div style="margin-top:10px" class="candode">
              <div style="margin-left: 34px">
                  <p class="hedat-contnt">搜索结果</p>
              </div>
              <!-- <div style="width: 17%">
              </div> -->
            </div>
          </div>
          <div class="floders-container floders">
            <div class="flodersList">
                <el-table
                class="tableLimit"
                ref="tableLimit"
                :data="tabelData"
                style="width: 100%;height:100%"
                @selection-change="selectAll"
                :row-key="rowKey"
                :expand-row-keys="expandRowKeys"
                @expand-change="expandChange"
                @select="select"
                :row-class-name="tableRowClassName"
              >
                <el-table-column type="selection" width="40" :selectable="selectAble"></el-table-column>
                <!-- 展开部分 -->
                <el-table-column type="expand" width="35" class-name="queryExpandButton">
                  <template slot-scope="props">
                    <el-table
                      class="expandLimit"
                      v-if="props.row.type!=1&&taskFileListVisible"
                      :data="props.row.details"
                      style="width: 100%"
                    >
                      <el-table-column label="产品名称" prop="productName">
                      </el-table-column>
                    
                      <el-table-column label="文件名称" prop="fileName">
                      </el-table-column>
                      <el-table-column min-width="120"
                        prop="comment"
                        label="备注"
                      >	
                      </el-table-column>
                      
                      <el-table-column
                        label="类型"
                      >	
                      <template slot-scope="scope">
                        <div>
                          <span>{{scope.row.taskType==0?'识别':scope.row.taskType==1?'图片转PDF':scope.row.taskType==2?'图片转Word':scope.row.taskType==3?'PDF转Word':scope.row.taskType==4?'截图->Word':scope.row.taskType==5?'PDF转Excel':scope.row.taskType==6?'PDF拆分':''}}</span>
                        </div>
                      </template>
                      </el-table-column>
                      <el-table-column
                        label="状态"
                      >	
                        <template slot-scope="scope">
                            <div>
                                <span :class="{'failStatus':scope.row.result==0,'finsishStatus':scope.row.result==1,'doingStatus':scope.row.result==2}"></span>
                                <span>{{scope.row.result==0?'识别失败':scope.row.result==1?'识别成功':scope.row.result==2?'识别中':''}}</span>
                            </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="makeTime"
                        label="创建时间"
                      >
                      </el-table-column>
                      <el-table-column label="操作" min-width="140">
                        <template slot-scope="scope">
                          <el-button type="text" size="small" @click="download(scope.row)" v-if="scope.row.taskType!=0&&scope.row.result==1">下载</el-button> 
                          <el-dropdown v-if="scope.row.taskType==0&&scope.row.result==1" @command="handleCommand">
                            <span class="el-dropdown-link">
                              导出<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" @click.native="commandClick(scope.row)">
                              <el-dropdown-item command="1">PDF</el-dropdown-item>
                              <el-dropdown-item command="2" v-if="scope.row.pid!=1001&&scope.row.pid!=1034">Excel</el-dropdown-item>
                              <el-dropdown-item command="3">Word</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <el-button type="text" size="small" @click="compaire(scope.row)" v-if="scope.row.taskType==0&&scope.row.pid!=1001&&scope.row.result==1" style="margin-left:10px">校对</el-button>
                          <el-button type="text" size="small" @click="updateRecord(scope.row)" v-if="(scope.row.taskType==0&&scope.row.pid==1001)||scope.row.taskType!=0||scope.row.result!=1" style="margin-left:10px">备注信息</el-button>
                          <el-button type="text" size="small" @click="deleteRecord(scope.row.recordId)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column label="名称">
                  <template slot-scope="scope">
                    <div class="noexpand" :type="scope.row.type"></div>
                    <div class="floder-div" @click="enterTak(scope.row)" v-if="scope.row.type==1">
                      <img src="../../assets/image/folder.png" alt="" class="folder-icon">
                      <span>{{scope.row.nameContent}}</span>
                    </div>
                    <div class="floder-div" v-else>
                      <span>{{scope.row.nameContent}}</span>
                    </div>
                  </template>
                </el-table-column>
                
                <el-table-column
                  prop="updateTime"
                  label="上次修改时间"
                >
                </el-table-column>
                <el-table-column label="操作" min-width="90">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteSelected(scope.row.id,scope.row.type)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="condition.currentPage"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="condition.pageSize"
                layout="slot, total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
      
    </div>
    <div class="heights tasklist" v-if="!folderList">
      <task-list :enterFolderRow="enterFolderRow" ref="taskList"></task-list>
    </div>
    <el-dialog
        title="修改备注信息"
        :visible.sync="dialogUpdateRecordResultVisible"
        v-if="dialogUpdateRecordResultVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="55%"
        height="100%"
        class="ocrTask"
        @close="clearUpdateRecord"
      >
        <update-record ref="updateRecord" :recordRow="recordRow"></update-record>
    </el-dialog>
    
    <el-dialog
        title="校对"
        :visible.sync="dialogCompaireFileResultVisible"
        v-if="dialogCompaireFileResultVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="90%"
        height="100%"
        class="compaire"
        @close="clearCompaireFile"
      >
        <compaire ref="compaireFile" :compaireRow="compaireRow" :formType="openType"></compaire>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
// let Base64 = require('js-base64').Base64
import taskList from './task'
import updateRecord from '../../components/updateRecord'
import compaire from '../../components/compaire'
export default {
  name: "myFolder",
  components: {
    updateRecord,
    taskList,
    compaire
  },
  data() {
    return {
      dialogUpdateRecordResultVisible:false,
      folderList:true,
      progress: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
      createDate: ["", ""],
      condition: {
        currentPage: 1,
        pageSize: 10,
      },
      pidTypesMap:{"通用文字识别":1001,"手写识别":1034,"身份证":2,"银行卡":4,"护照":8,"港澳通行证":1013,"户口本":1002,"外国人永久居留证":53,
      "结婚证":1004,"离婚证":1005,"军官证":11,"出生证":52,"社保卡":1039,"驾驶证":7,"行驶证":5,"VIN码":1,"机动车登记证":62,"机动车合格证":68,"增值税发票":21,
      "出租车票":83,"定额发票":84,"火车票":66,"行程单":82,"电子承兑汇票":1031,"机动车发票":61,"二手车发票":60,"营业执照":41,"开户许可证":1015,"全国各省市房产证":1033},
      pid:1001,
      taskType:'',
      taskPid:'',
      total: 0,
      tabelData: [],
      selectedFolderIds: [],
      expandRowKeys:[],   //展开行id的数组
      loading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      enterFolderRow:'',
      taskFileListVisible:true,
      hasNoRcgon:[],
      timer:'',
      dialogCompaireFileResultVisible:false,
      compaireRow:'',
      openType:'',
      exportType:'',
      recordRow:'',
      isWordExport:false   // 是否是文字导出
    };
  },
  computed:{
    keyWord(){
      return this.$store.state.queryWord
    }
  },
  mounted() {
    this.getQueryData()
    let that = this;
    this.$bus.on("refreshDataResult", () => {
      this.dialogUpdateRecordResultVisible = false;
      this.dialogCompaireFileResultVisible = false;
      this.getQueryData()
    });
    this.$bus.on("closeTaskResult", () => {
      this.folderList=true
      this.$nextTick(()=>{
        $(".noexpand[type='1']").parent().parent().prev().find(".cell").hide()
      })
    });
    this.$bus.on("closeUpdateRecordResultDialog", () => {
      this.dialogUpdateRecordResultVisible = false;
    });
    this.$bus.on("closeCompaireResultDialog", () => {
      this.dialogCompaireFileResultVisible = false;
    });
  },
  beforeDestroy() {
    this.$bus.off("refreshDataResult")
    this.$bus.off("closeTaskResult")
    this.$bus.off("closeUpdateRecordResultDialog")
    this.$bus.off("closeCompaireResultDialog")
    if(this.timer){
      clearInterval(this.timer)
      this.timer=null
    }
  },
  watch:{
    hasNoRcgon: {
      handler(val){
        if(val.length>0){
          if(!this.timer){
            this.timer=setInterval(() => {
              this.getQueryData()
            }, 3000);
          }
        }else{
          if(this.timer){
            clearInterval(this.timer)
            this.timer=null
          }
        }
      },
      deep: true
    }
  },
  methods: {
    select(selection,row){
      console.log(selection,row)
      row.selectBool=!row.selectBool
      var folertabel=[],tasktable=[]
      this.tabelData.forEach(item => {
        if(item.selectBool){
          if(item.type==1){
            folertabel.push(item)
          }else if(item.type==2){
            tasktable.push(item)
          }
        }
      });
      if(row.type==1){
        if(folertabel.length>0){
          this.tabelData.forEach(item => {
            if(item.type==2){
              item.canSelect=false
            }
          })
        }else{
          this.tabelData.forEach(item => {
            if(item.type==2){
              item.canSelect=true
            }
          })
        }
      }else{
        if(tasktable.length>0){
          this.tabelData.forEach(item => {
            if(item.type==1){
              item.canSelect=false
            }
          })
        }else{
          this.tabelData.forEach(item => {
            if(item.type==1){
              item.canSelect=true
            }
          })
        }
      }
    },
    selectAble(row,index){
      if(row.canSelect){
        return true
      }else{
        return false
      }
    },
    // 删除记录
    deleteRecord(id){
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          console.log(this.loading)
          if(this.loading){
            return
          }
          this.loading = true;
          var params={"recordIds": [id]}
          this.$ajax.post("/api/web/apiRecord/deleteRecord",params).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.getQueryData();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
            }
            this.loading = false;
          }).catch((err)=>{
            this.$notify({
              title: "提示",
              message: err,
              type: "error",
              duration: 1500,
            });
            this.loading = false;
          });
      }).catch((err) => {

      })
    },
    // 删除任务
    deleteSelectedTask(id) {
      if(this.selectedFolderIds.length==0&&!id){
        this.$message({message:'未选择数据',type: 'warning'})
        return
      }
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.loading = true;
          var ids=!id?this.selectedFolderIds.map((item) => item.id):[id];
          this.$ajax.post("/api/web/task/deleteTask",{taskIds: ids}).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.loading = false;
              this.getQueryData();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
              this.loading = false;
            }
          });
      }).catch((err) => {

      })
    },
    clearUpdateRecord(){
      this.$refs.updateRecord.clearAll()
    },
    // 修改备注
    updateRecord(row){
      this.recordRow=row
      this.$nextTick(()=>{
        this.dialogUpdateRecordResultVisible=true
      })
    },
    // 校对
    compaire(row){
      console.log(row)
      this.compaireRow=JSON.parse(JSON.stringify(row))
      this.openType='myrecord'
      this.$nextTick(()=>{
        this.dialogCompaireFileResultVisible=true
      })
    },
    download(row){
      var $a=document.createElement("a");
      $a.href='/api/web/apiRecord/downloadTransitionFile?recordId='+row.recordId
      var evObj=document.createEvent("MouseEvents");
      evObj.initEvent("click",false,false);
      $a.dispatchEvent(evObj);
    },
    // 选择导出类型
    handleCommand(command) {
      // pdf:1 ,excel:2 ,word:3
      console.log(command)
      this.exportType=command
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    rowKey(row){
      return row.id
    },
    expandChange(row, expandedRows){
      if(!row.flag){
        this.getTaskFileList(row)
      }
      row.flag=!row.flag
      if(!this.noPushExpand){
        this.expandRowKeys=[]
        expandedRows.forEach(item=>{
          this.expandRowKeys.push(item.id)
        })
        console.log(this.expandRowKeys)
      }
    },
    // 关键字查询
    getQueryData(){
      var formdata=new FormData()
      formdata.append("currentPage",this.condition.currentPage)
      formdata.append("pageSize",this.condition.pageSize)
      formdata.append("keyword",this.keyWord)
      this.$ajax.post("/api/web/selectAll/select",formdata).then(res=>{
        if(res.code==200){
            res.data.content.forEach(item=>{
              item.canSelect=true
              item.selectBool=false
              item.flag=false
              item.details=[]
              if(item.type!=1){
                this.getTaskFileList(item)
              }
            })
            this.tabelData = res.data.content;
            this.total = res.data.totalElements;
            console.log(this.tabelData)
            this.$nextTick(()=>{
              $(".noexpand[type='1']").parent().parent().prev().find(".cell").hide()
            })
        }
      })
    },
    // 获取任务下的所有识别记录
    getTaskFileList(row) {
      let formdata=new FormData()
      console.log("res",row.id)
      formdata.append('taskId',row.id)
      this.$ajax
        .post("/api/web/apiRecord/getRecords", formdata)
        .then((res) => {
          if (res.code == 200) {
            this.hasNoRcgon=[]
            res.data.forEach(item=>{
              if(item.result==2){
                this.hasNoRcgon.push(item)
              }
            })
            this.taskFileListVisible=false
            this.$set(row,'details',res.data)
            this.$nextTick(()=>{
              this.taskFileListVisible=true
            })
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 导出 单个|批量
    exportFolder(row,command){
      console.log(row)
      var param={}
      if(command){
        param.exportType=command
      }
      if(this.selectedFolderIds.length==0){
        this.$message({
          message:"请选择数据",
          type:'warning'
        })
        return
      }
      param.folderIds=this.selectedFolderIds.map((item)=> item.id)
      console.log(param)
      this.$ajax.post("/api/web/apiRecord/exportFolderResult",JSON.stringify(param)).then(res=>{
        if(res.code==200){
          if(res.data&&res.data.length>0){
            this.exportDown(res.data,command)
          }else{
            this.$notify({
              title: "提示",
              message: '没有可以导出的识别任务',
              type: "error",
              duration: 1500,
              customClass: "el-notification-error",
            }); 
          }
        }else{
         this.$notify({
            title: "提示",
            message: res.msg,
            type: "error",
            duration: 1500,
            customClass: "el-notification-error",
          }); 
        }
      })
    },
    // 导出 单个|批量
    commandClick(row,command){
      if(command){
        if(this.selectedFolderIds[0].type==1){
          this.exportFolder(row,command)
        }else{
          this.exportTask(row,command)
        }
      }else{  // 任务列表 单个导出
        this.exportTask(row,command)
      }
    },
    exportTask(row,command){
      console.log(row,command)
      if(command){
        this.exportType=command
      }
      var param={
        exportType:this.exportType
      }
      console.log(this.exportType)
      if(row){
        param.recordIds=[row.recordId]
      }else{
        if(this.selectedFolderIds.length==0){
          this.$message({
            message:"请选择数据",
            type:'warning'
          })
          this.canMoreExport=false
          return
        }
        var pids=[],num=0
        this.selectedFolderIds.forEach(item=>{
          if(item.details.length>0){
            if(item.details[0].taskType!=0){
              num++
            }else{
              pids.push(item.pid)
            }
          }
        })
        if(num==0){
          var pidDifferent=0
          pids.forEach(item=>{
            if(item!=pids[0]){
              pidDifferent++
            }
          })
          if(pidDifferent!=0){
            this.$message({
              message:"选择的识别任务文件中存在不同类型，不允许批量导出",
              type:"warning"
            }) 
            this.canMoreExport=false
            return
          }else{
            this.canMoreExport=true
          }
        }else{
          this.$message({
            message:"选择的任务文件中存在转换任务，不允许批量导出",
            type:"warning"
          })
          this.canMoreExport=false
          return
        }
        if(this.canMoreExport){
          let recordIds=[]
          this.selectedFolderIds.forEach(item=>{
            if(item.details.length>0){
              item.details.forEach(it=>{
                recordIds.push(it.recordId)
              })
            }
          })
          param.recordIds=recordIds
        }
      }
      console.log(param)
      if(param.recordIds&&param.recordIds.length>0){
        console.log(param)
        this.$ajax.post("/api/web/apiRecord/exportRecord",JSON.stringify(param)).then(res=>{
          if(res.code==200){
            this.exportDown(param.recordIds)
          }
        })
      }
    },
    exportDown(recordIds){
      var formHtml = "<form id='downLoadForm' style='display:none' method='post' action='/api/web/apiRecord/downloadRecordFile'>" + 
      "<input type='hidden' name='recordIds' value='" + JSON.stringify(recordIds) + "'>" +
      "<input type='hidden' name='exportType' value='" + this.exportType + "'>" +
      "</form>";
      var divDom = document.createElement("div");
      divDom.innerHTML = formHtml;
      document.body.appendChild(divDom);
      document.getElementById("downLoadForm").submit();
      document.body.removeChild(divDom);
    },
    selectAll(sels) {
      this.selectedFolderIds = sels;
      this.isWordExport=this.selectedFolderIds.every(item=>item.pid==1001||item.pid==1034)
    },
    enterTak(row){
      this.enterFolderRow=row
      this.tabelData.forEach(item=>{
        item.canSelect=true
      })
      this.selectedFolderIds=[]
      this.$nextTick(()=>{
        this.folderList=false
      })
    },
    addFolder(){
      this.dialogFloderVisible=true
    },
    deleteSelected(id,type) {
      if(id){
        if(type==1){
          this.deleteSelectedFolder(id)
        }else{
          this.deleteSelectedTask(id)
        }
      }else{
        if(this.selectedFolderIds[0].type==1){
          this.deleteSelectedFolder('')
        }else{
          this.deleteSelectedTask('')
        }
      }
    },
    deleteSelectedFolder(id){
      var ids = []
      ids=id?[id]:this.selectedFolderIds.map(item => {
        return item.id
      });
      if(ids.length==0){
        this.$message({message:'请选择文件夹',type: 'warning'})
        return
      }
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.loading = true;
          console.log(ids)
          this.$ajax.post("/api/web/userFolder/deleteFolder",JSON.stringify({folderIds:ids})).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.loading = false;
              this.getQueryData();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
              this.loading = false;
            }
          });
        }).catch((err) => {

      })
    },
    handleSizeChange(pageSize) {
      this.condition.pageSize = pageSize;
      this.getQueryData();
    },
    handleCurrentChange(page) {
      this.condition.currentPage = page;
      this.getQueryData();
    },
    clearCompaireFile(){
      this.$refs.compaireFile.clearAll()
    }
  },
};
</script>
<style type="text/css" scoped>

.tableLimit >>>.el-table__body-wrapper {
    height: calc(100% - 58px)!important;
    overflow: auto;
}
.compaire >>>.el-dialog {
    height: calc(100% - 8vh);
    margin-top: 5vh!important;
    margin: 0 auto;
}
.compaire >>>.el-dialog__body {
    height: calc(100% - 54px);
}
.floder-div{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchUser >>>.el-input__inner{
  border-radius: 20px;
}
.folder-icon{
  width: 28px;
  height: 24px;
  margin-right: 15px;
}
.btn-img.addtask {
    width: 13px;
    height: 13px;
}
img.btn-img {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}
.checkeone .btn-img{
    width: 17px;
    height: 18px;
}
.createCgon>>>span {
    display: flex;
    align-items: center;
}
.header-do {
    height: 50px;
    border-bottom: 2px solid #eee;
    display: flex;
    align-items: center;
}
.createCgon {
    margin-left: 34px;
    font-size: 13px;
}

.hedat-contnt {
    font-size: 15px;
    font-weight: 600;
}
.candode {
    margin-top: 12px;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 96%;
    height: 40px;
    margin: 0;
    justify-content: space-between;
}
.caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}
.sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
}
.sort-caret.ascending {
    border-bottom-color: #C0C4CC;
    top: 5px;
}
.sort-caret.descending {
    border-top-color: #C0C4CC;
    bottom: 7px;
}
.sort-caret.ascending.on{
  border-bottom-color: #409EFF;
}
.sort-caret.descending.on {
    border-top-color: #409EFF;
    bottom: 7px;
}
.createAll /deep/.el-input__inner {
    height: 31.6px;
    line-height: 31.6px;
    border: none;
    border-top: 1px solid #ccc;
    border-radius: 0;
}
.createAll /deep/.el-select__caret.el-input__icon.el-icon-arrow-up {
    line-height: 30px;
}
.createAll {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.taskCreate {
    display: flex;
    align-items: center;
}
.heights{
  width: 100%;
  height: calc(100% - 50px);
}
.type-task {
    width: 97px;
    background: rgb(233 247 250);
    border: 1px solid rgb(59 222 255);
    line-height: 30px;
    font-size: 13px;
    margin-right: 15px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
    width: 100%;
}
.el-date-editor /deep/.el-range-separator {
    width: 25px;
}
.taskicon{
  font-size: 17px;
  color: rgb(143 134 119);
  margin-right: 4px;
  margin-left: 4px;
}
.floder-icon{
  color: rgb(255,191,0);
}
.container {
  width: 100%;
  height: 100%;
}
.el-main {
  padding: 0;
  /* height: calc(100% - 40px); */
}
.el-container {
  height: 100%;
}
.floders-container.floders {
    height: calc(100% - 114px);
    width: 100%;
    padding: 0 0 6px;
    margin-top: 8px;
}
.sort-folder {
    display: flex;
    align-items: center;
    height: 50px;
    background: #f3f3f3;
}
.sort-method {
    width: 110px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}
.sort-method.on{
  background: #fff;
    color: #409EFF;
    border: 1px solid #eee;
}
.flodersList {
    height: calc(100%);
    overflow: auto;
    width: 100%;
}
.floder-each {
    padding-top: 15px;
}
.floder-each>div {
    width: 183px;
    height: 88px;
    float: left;
    border: 1px solid #e7e7e7;
    font-size: 13px;
    color: #818181;
    cursor: pointer;
    margin-top: 10px;
}
.floder-each>div:first-child {
    line-height: 88px;
    background: #fff;
    font-size: 14px;
}
.pictureNum {
    font-size: 12px;
    margin-left: 0px;
    padding-top: 2px;
    text-align: left;
    color: #484848;
}
.createTime {
    font-size: 12px;
    width: 100%;
    text-align: left;
    padding-left: 6px;
    padding-top: 3px;
    color: #484848;
}
.floder-each>.each-floder {
  position: relative;
  background: rgb(247,249,250);
}
.each-content {
    height: 73%;
    background: rgb(241,243,246);
    text-align: left;
    padding: 6px 3px 5px 6px;
}
.floder-name {
    color: #333333;
    padding-left: 6px;
    font-size: 14px;
}
.floder-introduce {
    padding-top: 3px;
    padding-left: 10px;
    font-size: 12px;
}

.el-date-editor .el-range-separator {
  width: 8% !important;
}
.container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.el-container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.content-do {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  box-sizing: border-box;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 12px;
}
.pagination {
    position: fixed;
    left: 0px;
    bottom: 10px;
    width: 100%;
    height: 42px;
}

.pagination .el-pagination {
  margin-top: 10px;
  margin-right: 10px;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  text-align: right;
}

.viewOpaginationCR .el-pagination__sizes {
  float: left;
  margin: 0 10px 0 5px;
  font-weight: 400;
  color: #606266;
}
</style>
