<template>
  <div class="container">
    <el-container>
      <!-- <el-header>
        <div class="headerD">
          <div class="leftT">译图合同智能比对系统</div>
          <div class="rightT">
            用户名: {{ this.$utils.getSessionStorage("user").username }}
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                个人中心<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="logOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header> -->
        <el-aside style="width: 230px" id="menuMain">
          <div class="header-title">
            <img class="icon" src="../assets/image/icon-1024.png" alt="">
            <span class="title-head">91OCR</span>
          </div>
          <div class="header-search">
            <el-input class="searchUser sousuoicon" v-model="allName" placeholder="搜索全部" prefix-icon="iconfont icon-sousuo" @keyup.native.enter="searchAll"></el-input>
          </div>
          <el-menu
            ref="menus-cai"
            :default-active="defaultActive"
            class="el-left-menu"
            background-color="rgb(0,21,41)"
            text-color="rgb(227 227 227)"
            active-text-color="rgb(10,123,215)"
            @select="handleSelect"
          >
            <!-- <el-menu-item v-for="(item,i) in menu" :index="item.name" :key="i" class="menu-left" :class="{'nochild':!item.children}">
              <div class="nodechil-n" v-if="!item.children">
                <span class="iconfont menu-icon" :class="item.icon"></span>
                <span slot="title" style="margin-left: 10px;">{{item.name}}</span>
              </div>
              <div class="nodechil-n haschild" v-if="item.children">
                <span class="iconfont menu-icon" :class="item.icon"></span>
                <span slot="title" style="margin-left: 10px;">{{item.name}}</span>
                <div class="menu-each">
                  <div class="each-menu-child" v-for="(it,index) in item.children" :key="index">
                    <span slot="title childmenu" style="margin-left: 10px;">{{it.name}}</span>
                  </div>
                </div>
              </div>
            </el-menu-item> -->
            <!-- 遍历路由表，生成左侧菜单 -->
            <template v-for="(item,index) in menu">
              <!-- 单层菜单-->
              <template v-if="!item.children">
                  <el-menu-item :index="item.name" :key="index" class="menu-left" :class="{'nochild':!item.children}" :menuname="item.content">
                    <div class="nodechil-n">
                      <span class="iconfont menu-icon" :class="classObject(item)" ></span>
                      <span slot="title" style="margin-left: 10px;" :class="{advicespan:item.name=='反馈记录'}">{{item.name}}</span>
                    </div>
                  </el-menu-item>
              </template>
              <!-- 多级菜单 -->
              <template v-else>
                <el-submenu :index="item.name" :key="index" class="menuName">
                  <template slot="title">
                    <span class="iconfont menu-icon" :class="item.icon"></span>
                    <span slot="title" style="margin-left: 10px;">{{item.name}}</span>
                  </template>
                  <!-- <div class="nodechil-n haschild"> -->
                    
                    <div class="menu-each">
                      <template v-for="(it,i) in item.children">
                        <el-menu-item :index="it.name" :key="i">
                          <div class="each-menu-child">
                              <span class="menu-childname" :name="it.name">{{it.name}}</span>
                          </div>
                        </el-menu-item>
                      </template>
                    </div>
                  <!-- </div> -->
                </el-submenu>
              </template>
            </template>
          </el-menu>
        </el-aside>
        <el-main>
          <div class="header">
            <div class="showhide">
              <!-- <span class="iconfont icon-weibiaoti25" @click="togoole('show')" v-show="isHide" title="展开"></span>
              <span class="iconfont icon-shouqi" @click="togoole('hide')" v-show="!isHide" title="收起"></span> -->
            </div>
            <div class="dosomethings">
              <span class="iconfont icon-sousuo sousuoicon" :class="{'show':showSearch}" @click="toSearch" v-if="false"></span>
              <el-input v-model="likename" :class="{'show':showSearch}" size="mini" placeholder="请输入名称或内容" class="searchLike"></el-input>
              <!-- <span class="el-icon-bell"></span> -->
              <div class="mine-mess">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    <span class="user-icon">
                      <img :src="userinfo.headImg" alt="" @error="errorImg">
                    </span>
                    <span class="user-name">{{userinfo.vxName}}</span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="mine">个人中心</el-dropdown-item>
                    <el-dropdown-item command="logOut">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>  
          <el-tabs 
            v-model="$store.state.editableTabsValue"
            type="card"
            @tab-remove="removeTab"
            @tab-click="handleClick"
             id="tabMain"
          >
            <el-tab-pane
              :key="index"
              v-for="(item, index) in $store.state.editableTabs"
              :label="item.name"
              :name="item.name"
              :closable="true"
              class="tabpane"
            >
              <tab-component :is="item.content" :type="item.type" v-if="tabPane" class="tabcomp" :ref="item.content"></tab-component>
            </el-tab-pane>
          </el-tabs>
          <!-- <div class="components-menu">
            <myfolder v-if="editableTabsValue=='我的文件夹'"></myfolder>
            <myrecord v-if="editableTabsValue=='我的记录'"></myrecord>
          </div> -->
        </el-main>
    </el-container>
    <el-drawer
      title="个人中心"
      :visible.sync="drawerMine"
      direction="rtl"
      class="mine-info"
      >
      <mine :userinfo="userinfo"></mine>
    </el-drawer>
     <el-dialog
        title=""
        v-if="dialogBingingVisible"
        :visible.sync="dialogBingingVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="igoreBing"
        width="40%"
        height="100%"
        class="bindingPhone"
        >
        <binding class="binding" ref="binding" :type="'binding'"> </binding>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import myrecord from './myRecord/index'
import myfolder from './myFolder/index'
import mine from './mine/index'
import compaire from '../components/compaire'
import binding from './bindingPhone/index'
import translate from './translate/index'
import searchResult from './searchResult/index'

import advice from './admin/advice/Index'
import counts from './admin/countDetail/Index'
export default {
  name: "index",
  data() {
    return {
      isCollapse: true,
      drawerMine:false,
      dialogBingingVisible:false,
      tabPane: true,
      menu: [],
      isHide:false,
      defaultActive:'',
      likename:'',
      showSearch:false,
      userinfo:{
        headImg: ''
      },
      allName:'',
      searchBool:false
    };
  },
  components: {
    myrecord,
    myfolder,
    mine,
    compaire,
    binding,
    advice,
    translate,
    searchResult,
    counts
  },
  created(){
    if(this.$utils.getSessionStorage('token')){
      this.getUserInfo()
      this.menu= [
        {name: '我的记录',content: 'myrecord',icon:'icon-lishixiao'},
        {name: '我的文件夹',content: 'myfolder',icon:'icon-wenjianjia1'},
        {name: '文档助手',content: 'translate',icon:'icon-bananzhushou',children:[
          {name: 'PDF转Word',content: 'translate',type:3},
          {name: '图片转PDF',content: 'translate',type:1},
          {name: 'PDF拆分',content: 'translate',type:6},
          {name: '图片转Word',content: 'translate',type:2},
          {name: 'PDF转Excel',content: 'translate',type:5},
        ]},
      ]
      if(this.$utils.getSessionStorage('roleCode')=="admin"){
        this.menu.push({name: '反馈记录',content: 'advice',icon:'icon-yijianfankui01'})
        this.menu.push({name: '统计分析',content: 'counts',icon:'icon-tongjifenxi'})
      }
      this.$store.commit("setDefaultTab",this.menu[0])
      this.defaultActive="我的记录"
      this.$nextTick(()=>{
        let that=this
        this.$refs['menus-cai'].open("文档助手")
        this.$refs.myrecord[0].getTaskList()
        $(".el-tabs__item").hide()
        $(".el-icon-arrow-down").hide()
        $(".el-submenu__title").click(function(){
          that.$refs['menus-cai'].open("文档助手")
        })
      })
    }
      if(location.href.indexOf("code")!=-1){
          //如果只是有code
          let formData=new FormData()
          formData.append('code',this.$store.state.loginParam.code)
          formData.append('state',this.$store.state.loginParam.state)
          //发请求，用code换token
          this.$ajax.post("/api/web/login/weChatCallback",formData).then(res=>{
            if(res.code==200){
                this.$store.commit("clearLoginParam")
                this.$utils.setSessionStorage('token', res.data.accessToken)
                this.$utils.setSessionStorage('roleCode', res.data.whetherAdmin==1?'admin':'user')
                this.menu= [
                  {name: '我的记录',content: 'myrecord',icon:'icon-lishixiao'},
                  {name: '我的文件夹',content: 'myfolder',icon:'icon-wenjianjia1'},
                  {name: '文档助手',content: 'translate',icon:'icon-bananzhushou',children:[
                    {name: 'PDF转Word',content: 'translate',type:3},
                    {name: '图片转PDF',content: 'translate',type:1},
                    {name: 'PDF拆分',content: 'translate',type:6},
                    {name: '图片转Word',content: 'translate',type:2},
                    {name: 'PDF转Excel',content: 'translate',type:5},
                  ]},
                ]
                if(res.data.whetherAdmin==1){
                  this.menu.push({name: '反馈记录',content: 'advice',icon:'icon-yijianfankui01'})
                  this.menu.push({name: '统计分析',content: 'counts',icon:'icon-tongjifenxi'})
                }
                this.$store.commit("setDefaultTab",this.menu[0])
                this.defaultActive="我的记录"
                this.$nextTick(()=>{
                  let that=this
                  this.$refs['menus-cai'].open("文档助手")
                  this.$refs.myrecord[0].getTaskList()
                  $(".el-tabs__item").hide()
                  $(".el-icon-arrow-down").hide()
                  $(".el-submenu__title").click(function(){
                    that.$refs['menus-cai'].open("文档助手")
                  })
                })
                this.getUserInfo()
                if(res.data.hasBinding==0){
                  this.dialogBingingVisible=true
                }
                // 清除地址后的参数
                var url = window.location.href;
                if (url.indexOf("?") != -1) {
                  url = url.replace(/(\?|#)[^'"]*/, '');
                  window.history.pushState({}, 0, url);
                }
            }
          }).catch(err=>{
              console.log(err)
          })
      }
  },
  mounted() {
    if(this.$utils.getSessionStorage('token')){
      this.$nextTick(()=>{
        let that=this
        this.$refs['menus-cai'].open("文档助手")
        this.$refs.myrecord[0].getTaskList()
        $(".el-tabs__item").hide()
        $(".el-icon-arrow-down").hide()
        $(".el-submenu__title").click(function(){
          that.$refs['menus-cai'].open("文档助手")
        })
      })
    }
    
    this.$bus.on("closeBindDialog",()=>{
        this.dialogBingingVisible=false
    })
    this.$bus.on("logOut", () => {
      this.logOut();
    });
    this.$bus.on("hideMenu", () => {
      if(!$(".el-aside").hasClass("asidehide")){
        $(".el-aside").addClass("asidehide")
      }
      if(!$(".showhide").hasClass("ishide")){
        $(".showhide").addClass("ishide")
      }
      this.isHide = true;
    });
    this.$bus.on("closeTab", tabName =>{
      this.removeTab(tabName)
    })
    
    this.$bus.on("openTab", (tabName,param) =>{
      console.log(tabName,param)
      let hasTabs=this.$store.state.editableTabs
      let hasH=hasTabs.filter(item=>{
        if(item.name==tabName){
          return true
        }
      })
      console.log(hasH)
      this.$store.commit("setPageParam",param)
      if(hasH.length==0){
        var obj={}
        obj.name="我的文件夹"
        if(tabName=="我的文件夹"){
          obj.content="myfolder"
        }else if(tabName=="我的记录"){
          obj.content="myrecord"
        }else if(tabName=="我的记录"){
          obj.content="useradvice"
        }
        this.$store.commit("add_tabs",obj)
        this.$store.commit('set_active_index',tabName);
        this.defaultActive=tabName
      }else{
        this.$store.commit('set_active_index',hasH[0].name);
        this.defaultActive=hasH[0].name
      }
      console.log(this.defaultActive,tabName)
      if(tabName=="我的记录"){
        this.$refs.myrecord[0].getTaskList()
      }else if(tabName=="我的文件夹"){
        let vmFolder=this.$refs.myfolder[0]
        if(vmFolder.folderList){
          vmFolder.getFolderList()
        }else{
          vmFolder.$refs.taskList.getTaskList()
        }
      }
    })
  
    this.$bus.on("refreshUserInfo", ()=>{
      this.getUserInfo()
    })
  },
  beforeDestroy(){
    this.$bus.off("closeBindDialog")
    this.$bus.off("logOut")
    this.$bus.off("hideMenu")
    this.$bus.off("closeTab")
    this.$bus.off("openTab")
    this.$bus.off("refreshUserInfo")
  },
  updated(){
      this.$nextTick(()=>{
        $(".el-tabs__item").hide()
        if(this.searchBool){
          this.defaultActive=""
        }
      })
  },
  methods: {
    errorImg(){
      $(".user-icon>img").attr("src",require("../assets/image/mine.png"))
    },
    classObject (item) {
      return [
        item.icon,
        item.name=="反馈记录"?"adviceicon":""
      ]
    },
    searchAll(){
        this.searchBool=true
        var $dom=$(".el-icon-close").eq(0)
        if($dom&&$dom[0]){
          $dom[0].click()
        }
        setTimeout(() => {
        let hasTabs = this.$store.state.editableTabs
        let hasH = hasTabs.filter((item) => {
          if (item.name == "搜索全部") {
            return true;
          }
        });
        if (hasH.length == 0) {
          this.$store.commit("add_tabs", {
            name: "搜索全部",
            content: "searchResult",
            icon:'noicon'
          });
          this.$store.commit("set_active_index","搜索全部")
        } else {
          console.log(hasH);
          this.$store.commit("set_active_index", "搜索全部");
        }
        this.$store.commit("setQueryKey", this.allName);
        }, 10);
    },
    // 获取用户信息
    getUserInfo(bool){
      this.$ajax.get("/api/web/user/getUserMessage").then(res=>{
          if(res.code==200){
              this.userinfo=res.data
              if(bool){
                this.$nextTick(()=>{
                  this.drawerMine=true
                })
              }
          }
      })
    },
    igoreBing(done){  // 跳过绑定
        this.$router.replace({path: "/index",});
        done()
    },
    toSearch(){
      this.showSearch=!this.showSearch
    },
    togoole(status){
      $(".el-aside").toggleClass("asidehide")
      $(".showhide").toggleClass("ishide")
      this.isHide=!this.isHide
    },
    handleSelect(key, keyPath) {
      this.searchBool=false
      if(key!=this.defaultActive){
        var $dom=$(".el-icon-close").eq(0)
        if($dom&&$dom[0]){
          $dom[0].click()
        }
      }
      console.log(key,keyPath)
      setTimeout(() => {
      let isAdd = true;
      this.$store.state.editableTabs.forEach((item) => {
        if (item.name == key) {
          console.log("存在,则高亮该项目");
          this.$store.commit("set_active_index",item.name)
          isAdd = false;
        }
        if(key=='我的文件夹'){
          this.tabPane=false
          this.$nextTick(()=>{
            this.tabPane=true
          })
        }
      });
      if (isAdd) {
        console.log("不存在,则插入到项目中");
        this.menu.forEach(item=>{
          if(item.children&&item.children.length>0){
            item.children.forEach(it=>{
              if(it.name==key){
                this.$store.commit("add_tabs",it)
                this.$store.commit("set_active_index",it.name)
              }
            })
          }else{
            if(item.name==key){
              this.$store.commit("add_tabs",item)
              this.$store.commit("set_active_index",item.name)
            }
          }
        })
      }else{
        if(key=="我的记录"){
          this.$refs.myrecord[0].getTaskList()
        }else if(key=="我的文件夹"){
          let vmFolder=this.$refs.myfolder[0]
          if(vmFolder.folderList){
            vmFolder.getFolderList()
          }else{
            vmFolder.$refs.taskList.getTaskList()
          }
        }
      }
      this.defaultActive=key
      }, 5);
      this.$nextTick(()=>{
        setTimeout(() => {
          $(".el-tabs__item").hide()
        }, 10);
      })
    },
    handleClick(tab, event) {
      this.defaultActive=tab.name
      console.log(tab, event);
    },
    removeTab(targetName) {
      console.log(this.defaultActive)
      this.$store.commit("delete_tabs",targetName)
      // if (this.$store.state.editableTabsValue == targetName) {
      //   // 设置当前激活的路由
      //   this.$store.commit("set_active_index",this.$store.state.editableTabs[this.$store.state.editableTabs.length-1].name)
      //   this.defaultActive=this.$store.state.editableTabs[this.$store.state.editableTabs.length-1].name
      // }
      console.log(this.defaultActive)
    },
    logOut() {
      this.$utils.removeSessionStorage("token");
      this.$utils.removeSessionStorage("user");
      this.$router.push({ path: "/login" });
      this.$store.commit("clearLoginParam")
    },
    handleCommand(command) {
      if (command == "logOut") {
        this.logOut();
      }else if(command == "mine"){
        console.log('mine')
        this.getUserInfo(true)
      //   let hasTabs=this.$store.state.editableTabs
      //   let hasH=hasTabs.filter(item=>{
      //     if(item.name=='个人中心'){
      //       return true
      //     }
      //   })
      //   if(hasH.length==0){
      //     this.$store.commit("add_tabs",{
      //       name: '个人中心',
      //       content: 'mine',
      //     })
      //     this.$store.commit('set_active_index','个人中心');
      //   }else{
      //     this.$store.commit('set_active_index',hasH[0].name);
      //   }
      //   let param = {
      //     row:JSON.parse(JSON.stringify(row)),
      //     type:'myfolder',
      //     folderId:this.folderId
      //   }
      //   this.$store.commit("setCompaireParam",param)
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.el-menu .menu-each li {
    height: 38px;
    line-height: 38px;
}
.menuName /deep/.el-submenu__title {
    background: #F4F4F4!important;
    padding-left: 0!important;
    color: #484848!important;
    font-size: 15px;
}
.menuName /deep/.el-menu{
    background: #F4F4F4!important;

}
.menu-each {
    line-height: 33px;
    padding-left: 14px;
}
.haschild{
  border-top: 1px solid #ccc;
}
.nochild:first-child .nodechil-n{
  border-top: 1px solid #ccc;
}
.header-search {
    width: 85%;
    background: #F4F4F4;
    margin: 0 auto;
    border-radius: 9px;
    margin-bottom: 18px;
}
.header-search /deep/.el-input__inner::placeholder{
  color: rgb(163, 163, 163);
}
.header-search /deep/.el-input__inner{
    background: #E8E8E8;
    letter-spacing: 0.29px;
}
.menu-icon {
  font-size: 19px;
  color: #828282;
}
.mine-info /deep/.el-drawer__close-btn:focus{
  outline: none;
}
.bindingPhone /deep/.el-dialog__headerbtn {
    font-size: 14px;
    top: 10px;
}
.bindingPhone /deep/.el-dialog{
    background: rgb(240,242,245);
}
.searchLike{
  width: 0;
  display: none;
  opacity: 0;
  margin-right: 15px;
  transition: all .3s;
}
.searchLike /deep/input{
  border: none;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 0;
}
.dosomethings>span.show{
  margin-right: 0;
}
.searchLike.show{
  width: 152px;
  opacity: 1;
  display: block;
}
.el-drawer__wrapper /deep/.el-drawer.rtl:focus{
  outline: none;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    box-shadow: 0px 2px 1px #ededed;
    height: 43px;
}
.header{
  position: absolute;
  right: 0;
  height: 50px;
  box-shadow: none;
  z-index: 20;
}
.dosomethings>span{
  cursor: pointer;
}
.container {
  width: 100%;
  height: 100%;
}
.components-menu {
    height: calc(100% - 55px);
}
.el-header {
    padding: 0 
}
.el-container {
  height: 100%;
}
main.el-main {
    padding: 0 0;
    overflow: hidden;
}
.el-tabs >>>.el-tabs__header{
  margin-bottom: 0;
  height: 0;
  /* height: 50px; */
  /* margin-left: 27px; */
}
.el-tabs >>>.el-tabs__item{
  height: 50px;
  line-height: 50px;
}
.el-aside {
  width: 200px;
  height: 100%;
  transition: all .3s;
  background: #F4F4F4;
}
.el-aside.asidehide{
  width: 55px!important;
  overflow: hidden;
}
.el-aside li {
    padding-left: 15px!important;
    background: #F4F4F4!important;
    color: #484848!important;
    font-size: 14px;
}
.el-menu-item i{
    color: #595A5B!important;
}
.el-menu-item.is-active span{
    color: #006FFF!important;
}
.el-aside.asidehide li {
    /* text-align: center; */
}
.showhide {
    cursor: pointer;
    transition: all .3s;
}
.showhide.ishide{
  left: 57px;
  top: 13px;
}
.showhide span {
    font-size: 16px;
}
.showhide.ishide span{
  font-size: 21px;
}
.el-left-menu {
  height: calc(100% - 130px);
  text-align: left;
  padding-top: 5px;
  background-color: #F4F4F4!important;
}

/* .el-left-menu:not(.el-menu--collapse) {
  width: 250px;
  min-height: 400px;
} */

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.el-menu-item.is-active::after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 27px;
    background: rgb(10,123,215);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.headerD {
  background-color: rgb(0,21,41);
  width: 100%;
  height: 100%;
  border-bottom: solid 1px white;
  display: flex;
  justify-content: space-between;
  color: white;
}

.headerD div {
  margin: auto 0px;
}

.leftT {
  padding-left: 20px;
}

.rightT {
  padding-right: 20px;
}

#indexMain {
    height: calc(100% - 60px);
    position: relative;
    overflow: hidden;
}

#openClose {
  position: absolute;
  left: 277px;
  width: 20px;
  padding-left: 5px;
  padding-right: 5px;
  top: 40%;
}

.el-tabs,
.el-tabs__content {
  height: 100%;
}
.el-tab-pane, .tabcomp {
    height: 100%;
}
.header-title {
    width: 229.9px;
    height: 68px;
    line-height: 50px;
    background: #F4F4F4;
    display: flex;
    align-items: center;
}
.icon {
    width: 38px;
    margin-right: 16px;
    margin-left: 16px;
}
.title-head{
  font-size: 29px;
  color: #000000;
  letter-spacing: 0.53px;
}
.el-menu-item.is-active{
    color: #006FFF!important;
    background-color:#F4F4F4!important;
    padding-left: 15px!important;
    font-weight: 600;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color:  #F4F4F4!important;
}
.dosomethings>span {
    margin-right: 23px;
    font-size: 15px;
    font-weight: 600;
}
.dosomethings {
    display: flex;
    align-items: center;
}
.user-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 6px;
}
.user-icon img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-name {
    color: #6c6c6c;
    font-size: 15px;
}
span.el-dropdown-link.el-dropdown-selfdefine {
    display: flex;
    align-items: center;
}
.el-drawer__wrapper /deep/.el-drawer__header>:first-child:focus{
  outline: none;
}
.adviceicon{
  font-size: 23px;
  margin-left: -2px;
}
.advicespan{
  margin-left: 8px!important;
}
*:focus,i:focus{
  outline: none;

}
</style>
<style>
.sousuoicon .el-input__prefix{
  left: 12px;
}
.sousuoicon .el-input__inner{
  padding-left: 38px;
}
.el-tabs__content {
  height: calc(100% - 3px);
}
#pane-1 {
  height: 100%;
}
</style>
