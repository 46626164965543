<template>
    <div class="mine-content">
        <div class="mine-contence">
            <div class="ime-header">
                <span class="mine-icon"><img :src="userinfo.headImg" alt="" @error="errorImg"></span>
                <span class="mine-name">{{userinfo.vxName}}</span>
            </div>
            <div class="count-size">
                <el-tooltip placement="top" :content="usePrcent+'%'" class="progress-cont" effect="light">
                    <el-progress :percentage="usePrcent" :show-text="false"></el-progress>
                </el-tooltip>
                <p class="size-use">
                    <span class="hasuse">{{userinfo.useMemory}}</span> /
                    <span class="alluse">{{userinfo.countMemory}}</span>
                </p>
            </div>
            <div class="mine-some">
                <div class="userinfos">
                    <div class="user-eachinfo">
                        <span class="info-titile">微信名</span>
                        <span class="info-contence">{{userinfo.vxName}}</span>
                    </div>
                    <div class="user-eachinfo">
                        <span class="info-titile">邮箱</span>
                        <el-input v-model="userMail" size="mini" class="emailInput"></el-input>
                    </div>
                    <div class="user-eachinfo">
                        <span class="info-titile">手机号</span>
                        <span class="info-contence">{{userinfo.phoneNumber}}</span>
                        <span class="changePhone" @click="bingPhone">{{userinfo.phoneNumber?'去更换':'去绑定'}}</span>
                    </div>
                    <!-- <div class="user-eachinfo">
                        <span class="info-titile">地区</span>
                        <span class="info-contence">{{userinfo.address}}</span>
                    </div> -->
                </div>
            </div>
            <div class="updateMine" @click.stop="update">
                修改信息
            </div>
        </div>

        <el-dialog
            title=""
            v-if="dialogBingingVisible"
            :visible.sync="dialogBingingVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true"
            @close="clearAll"
            width="40%"
            height="100%"
            class="bindingPhone"
            >
            <bind-phone class="binding" ref="binding" :type="'change'"> </bind-phone>
        </el-dialog>
    </div>
</template>

<script>
import bindPhone from '../bindingPhone/index'
export default {
    name: '',
    props: ["userinfo"],
    data(){
        return {
            dialogBingingVisible:false,
            usePrcent:0,
            userMail:'',
            phoneMsg:'绑定',
        }
    },
    components:{
        bindPhone
    },
    watch: {
        userinfo:{
            handler(newVal){
                if(JSON.stringify(newVal)!="{}"){
                    this.userMail=this.userinfo.userMail
                    this.getUsePrcent()
                }
            },
            deep: true
        }
    },
    mounted(){
        if(JSON.stringify(this.userinfo)!="{}"){
            this.userMail=this.userinfo.userMail
            this.getUsePrcent()
        }
        // 还原
        $(document).bind("click", () => {
            this.userMail=this.userinfo.userMail
        });
        $(".emailInput").bind("click", function(e) {
            if (e.stopPropagation) e.stopPropagation();
            else e.cancelBubble = true;
        });

        this.$bus.on("closeBindDialog",()=>{
            this.dialogBingingVisible=false
        })
    },
    beforeDestroy(){
        this.$bus.off("closeBindDialog")
    },
    methods:{
        errorImg(){
            $(".mine-icon>img").attr("src",require("../../assets/image/mine.png"))
        },
        clearAll(){
            this.$refs.binding.clearAll()
        },
        bingPhone(){
            this.dialogBingingVisible=true
        },
        update(e){
            if (e.stopPropagation) e.stopPropagation();
            else e.cancelBubble = true;
            let mail=new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
            if(this.userMail === ""){ //输入不能为空
                this.$message({
                    message:'邮箱不能为空',
                    type:'warning'
                })
                return
            }else if(!mail.test(this.userMail)){ //正则验证不通过，格式不对
                this.$message({
                    message:'邮箱格式错误',
                    type:'warning'
                })
                return
            }
            let formData=new FormData()
            formData.append("userMail",this.userMail)
            this.$ajax.post("/api/web/user/saveUserMail",formData).then(res=>{
                if(res.code==200){
                    this.$notify({
                        title: "提示",
                        message: "修改成功",
                        type: "success",
                        duration: 1500,
                        customClass: "el-notification-error",
                    });
                    this.$bus.emit("refreshUserInfo")
                }else{
                    this.$notify({
                        title: "提示",
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                        customClass: "el-notification-error",
                    });
                }
            }).catch(err=>{
                this.$notify({
                    title: "提示",
                    message: "修改成功",
                    type: "服务器连接异常",
                    duration: 1500,
                    customClass: "el-notification-error",
                });
            })
        },
        getUsePrcent(){
            if(this.userinfo.useMemory&&this.userinfo.countMemory){
                this.usePrcent=Number(Number(((this.userinfo.useMemory.slice(0,-2))/(this.userinfo.countMemory.slice(0,-2)))*100).toFixed(1))
            }   
        },
    }
}
</script>

<style scoped>
    .mine-content {
    background: rgb(250,250,250);
    padding: 24px;
}
.ime-header {
    display: flex;
    align-items: center;
}
span.mine-icon {
    display: inline-block;
    width: 48px;
    height: 45px;
    border-radius: 50%;
    margin-right: 21px;
}
span.mine-icon>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.count-size {
    margin-top: 30px;
    font-size: 13px;
}
p.size-use {
    text-align: left;
    padding-top: 5px;
    color: black;
}
span.hasuse {
    color: rgb(51 165 239);
}
.mine-some {
    padding: 20px 0 70px;
    background: #fff;
    margin-top: 24px;
    font-size: 14px;
}
.updateMine {
    background: rgb(85,118,159);
    width: 92px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    border-radius: 17px;
    margin: 20px auto 0;
    cursor: pointer;
}
.el-progress /deep/.el-progress-bar__outer{
    height: 15px!important;
}
.el-form-item{
    margin-bottom: 1px;
}
.el-form-item /deep/.el-form-item__label {
    font-weight: 600;
}
.el-form-item /deep/.el-input__inner{
    border: none;
}
.userinfos>.user-eachinfo {
    position: relative;
    padding: 9px 20px;
    text-align: left;
    display: flex;
    align-items: center;
}
.user-eachinfo .el-input {
    width: 80%;
}
.user-eachinfo /deep/input {
    border: none;
    font-size: 14px;
    padding: 0 7px;
}
.info-titile {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    width: 20%;
}
span.info-contence {
    margin-left: 7px;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.changePhone {
    position: absolute;
    color: blue;
    right: 20px;
    cursor: pointer;
}


.bindingPhone /deep/.el-dialog__headerbtn {
    font-size: 14px;
    top: 10px;
}
.bindingPhone /deep/.el-dialog{
    background: rgb(240,242,245);
}
</style>