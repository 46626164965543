<template>
    <div class="compaire">
        <div class="compaire-contence">
            <el-form :model="channel" label-width="100px" ref="dataForm" >
                <el-form-item label="备注信息" style="width: 83%;">
                    <el-input type="textarea" v-model="channel.comment" placeholder="请输入备注信息"></el-input>
                </el-form-item>
                <el-button size="mini" class="sureUpdate" type="primary" @click="submitForm">确定</el-button>
            </el-form>
            <!-- 工具栏 -->
            <!-- <div class="commons">
                <i class="iconHeight" @click="heightView = true" title="适应高度"></i>
                <i class="iconWidth" @click="heightView = false" title="适应宽度"></i>
            </div> -->
            <div class="compaire-content">
                <div class="left-img">
                    <img id="recordImg" :src="compaireRow.picPath" :class="imgClass" alt="">
                </div>
                <div class="right-contence" v-if="compaireRow.pid==1034||compaireRow.pid==1">
                    <div class="right-each" v-for="(item,i) in compaireRow.ocrResult" :key="i" style="margin-bottom: 5px;">
                        <div class="right-result-each" v-for="(val,key,index) in item" :key="index">
                            <p class="str-key" style="text-align: left;">{{key}}</p>
                            <p class="result-each str-each" v-for="(it,ind) in changeResult(val)" :key="ind">
                                <input type="text" class="result-row" :value="it">
                            </p>
                        </div>
                    </div>
                </div>
                <div class="right-contence" v-else>
                    <div class="right-each" v-for="(item,i) in compaireRow.ocrResult" :key="i" style="margin-bottom: 5px;">
                        <div class="result-each" v-for="(val,key,index) in item" :key="index" >
                            <input type="text" class="result-row result-row-key" :value="key" style="width:30%">
                            <template v-if="Object.prototype.toString.call(val)=='[object Array]'">
                            <input type="text" class="result-row result-row-val" disabled style="width:70%"><br>
                            <div class="right-each2" v-for="(cit,ci) in val" :key="ci" style="margin-bottom: 5px;">
                                <p class="result-each" v-for="(twoval,twokey,twoindex) in cit" :key="twoindex" >
                                    <input type="text" class="result-row result-row-key" :value="twokey" style="width:30%">
                                    <input type="text" class="result-row result-row-val" :value="twoval" style="width:70%">
                                </p>
                            </div>
                            </template>
                            <input type="text" class="result-row result-row-val" v-else :value="val" style="width:70%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props:['compaireRow','formType','folderId'],
    data(){
        return {
            loading:false,
            imgClass: "imgWidthAuto", //图片的class，需要根据heightView决定
            heightView:true,
            channel:{
                comment: '',
            },
            typeMap:{
                "myrecord":"我的记录",
                "myfolder":"我的文件夹"
            }
        }
    },
    computed:{
        // compaireRow(){
        //     return this.$store.state.compaireParam.row
        // },
        // formType(){
        //     return this.$store.state.compaireParam.type
        // }
    },
    mounted(){
        this.channel.comment=this.compaireRow.comment
        console.log(this.$store.state.compaireParam,this.compaireRow)
    },
    methods:{
        submitForm(){
            if (this.loading) {
                return;
            }
            this.loading=true
            let formData = new FormData();
            formData.append("recordId", this.compaireRow.recordId);
            formData.append("comment", this.channel.comment);
            var ocrResult=[]
            if(this.compaireRow.pid==1034||this.compaireRow.pid==1){
                $(".right-each").each(function(){
                    var param={},ocrStr=''
                    $(this).children(".right-result-each").each(function(){
                        let key=$(this).children(".str-key")[0].innerText
                        let strEachLength= $(this).children(".str-each").length
                        $(this).children(".str-each").each(function(index){
                            var it=$(this).children(".result-row")[0].value.replace(/ /ig,"&nbsp;")
                            if(index==strEachLength-1){
                                ocrStr=ocrStr+it
                            }else{
                                ocrStr=ocrStr+it+"\n"
                            }
                        })
                        param[key]=ocrStr
                    })
                    ocrResult.push(param)
                })
            }else{
                $(".right-each").each(function(){
                    var param={}
                    $(this).children(".result-each").each(function(){
                        var $deepChild=$(this).children(".right-each2")
                        let key=$(this).children(".result-row-key")[0].value
                        if($deepChild.length){
                            param[key]=[]
                            $deepChild.each(function(){
                                var deepParam={}
                                $(this).children(".result-each").each(function(){
                                    let deepkey=$(this).children(".result-row-key")[0].value
                                    let deepval=$(this).children(".result-row-val")[0].value
                                    deepParam[deepkey]=deepval
                                })
                                param[key].push(deepParam)
                            })
                        }else{
                            let val=$(this).children(".result-row-val")[0].value
                            param[key]=val
                        }
                    })
                    ocrResult.push(param)
                })
            }
            formData.append("ocrResult", JSON.stringify(ocrResult));
            console.log(JSON.stringify(ocrResult))
            this.$ajax.post("/api/web/apiRecord/saveOcrResult",formData).then(res=>{
                if(res.code==200){
                    this.$notify({
                        title: "提示",
                        message: "修改成功",
                        type: 'success',
                        duration: 1000,
                        customClass: "el-notification-error",
                    });
                    this.$bus.emit("refreshDataRecord");
                    this.$bus.emit("refreshDataFolderTask");
                    this.$bus.emit("closeCompaireResultDialog")
                    this.$bus.emit("refreshDataFolderTaskResult")
                    // this.$bus.emit('closeTab',"校对详情")
                    // this.$bus.emit("openTab",this.typeMap[this.formType],{folderId:this.folderId})
                } else {
                    this.$notify({
                        title: "提示",
                        message: res.msg,
                        type: "error",
                        duration: 1500,
                    });
                }
                this.clearAll();
            }).catch(err=>{
                this.clearAll();
            })
        },
        changeResult(rowStr){
            var resultArr=[]
            if(this.compaireRow.pid==1034){
                let str=rowStr
                resultArr=str.split("\n").map((item,index)=>{
                    let reg=/&nbsp;/ig
                    return item.replace(reg," ")
                })
            }else if(this.compaireRow.pid==1){
                let str=this.compaireRow.ocrResult[0]['车架号']
                resultArr=[str]
            }
            // console.log(resultArr)
            return resultArr
        },
        clearAll(){
            this.loading= false
            // this.imgClass= "imgHeightAuto"
            this.heightView= true
        }
    },
    watch:{
        compaireRow:{
            handler(newVal){
                if(newVal){
                    this.clearAll()
                    this.channel.comment=newVal.comment
                }
            },
            deep:true
        },
        heightView(newVal) {
            let oldWidth = $("#PreviewDoc").width();
            let scale;
            // this.imgClass = newVal ? "imgHeightAuto" : "imgWidthAuto";
            this.imgClass = "imgWidthAuto"
            this.$nextTick(() => {
                this.imgWidth = $("#PreviewDoc").width();
                scale = oldWidth / this.imgWidth;
                if (newVal) {
                $("#PreviewDoc").width("");
                $(".resultUl").css({
                    fontSize: "13px",
                    lineHeight: "24px",
                });
                } else {
                $(".resultUl").css({
                    fontSize: 13 / 0.91 + "px",
                    lineHeight: 24 / 0.91 + "px",
                });
                }
                this.oldFontSize = (13 / scale) * 0.85;
                this.oldLineHeight = (24 / scale) * 0.85;
            });
            if (newVal) {
                this.$refs["PreviewDoc"].style.left = "50%";
                this.$refs["PreviewDoc"].style.top = "50%";
            } else {
                this.$refs["PreviewDoc"].style.left = "0";
                this.$refs["PreviewDoc"].style.top = "0";
            }
        },
    }
}
</script>

<style scoped>
.el-form {
    position: relative;
    margin-top: 10px;
}
.compaire {
    height: 100%;
}
.compaire-contence {
    height: 100%;
}
.compaire-content {
    display: flex;
    padding: 0 10px 0;
    height: calc(100% - 86px);
    overflow: hidden;
}
.compaire-content>div {
    flex: 1;
    height: 100%;
    overflow: auto;
}
.left-img {
    position: relative;
    background: #ccc;
}
.sureUpdate {
    position: absolute;
    right: 39px;
    top: 11px;
}
.right-contence {
    padding: 0 10px;
    font-size: 15px;
}
.result-each {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}
.result-row {
    width: 100%;
    padding: 5px;
}
.result-row:focus {
    outline: none;
}

.commons {
  height: 100%;
  padding-right: 20px;
}
.commons i {
  width: 20px;
  color: white;
  font-size: 32px;
  margin-left: 5px;
  cursor: pointer;
}
.iconWidth {
  background: url(../assets/image/width.png) no-repeat center center;
  display: inline-block;
  background-size: 32px 32px;
  width: 32px !important;
  height: 32px !important;
  position: relative;
  top: 5px;
  left: -10px;
}

.iconHeight {
  background: url(../assets/image/height.png) no-repeat center center;
  display: inline-block;
  background-size: 32px 32px;
  width: 32px !important;
  height: 32px !important;
  position: relative;
  top: 5px;
}
.imgHeightAuto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.imgWidthAuto {
  width: 100%;
  min-width: 100%;
  display: block;
  /* position: relative;
    left: 0;
    top: 0; */
}
</style>