<template>
    <div class="container">
        <div class="empty-head"></div>
    <el-container>
      <el-main>
        <div class="does">
          <div style="margin-top:10px" class="candode">
              <div style="margin-left: 34px">
                  <p class="hedat-contnt">反馈记录</p>
              </div>
              <div style="width: 17%">
                <el-input class="searchUser sousuoicon" v-model="condition.userId" placeholder="搜索用户" prefix-icon="iconfont icon-sousuo" @keyup.native.enter="getTaskList"></el-input>
              </div>
          </div>
        </div>
        <div class="advice-container adivceAdmin">
          <el-table
                class="tableLimit"
                ref="tableLimit"
                :data="tabelData"
                border
                style="width: 100%;height:100%"
                @selection-change="selectAll"
                @header-dragend="headerDragend"
                @row-click="seeRowDetail"
              >
                <el-table-column label="用户ID" prop="userId" align="center"></el-table-column>
                <el-table-column label="用户名" prop="oneSelfName" align="center"></el-table-column>
                <el-table-column label="反馈ID" prop="feedbackId" align="center"></el-table-column>
                <el-table-column label="提交时间" prop="makeTime" :resizable="false" align="center"></el-table-column>
                <el-table-column label="是否回复" prop="userId" align="center">
                    <template slot-scope="scope">
                        <span :class="{'el-icon-check': scope.row.whetherReply==1}" style="font-weight: 600;color:red"></span>
                    </template>
                </el-table-column>
                <el-table-column label="奖励内存/有效期" prop="award" :resizable="false" align="center"></el-table-column>
                <el-table-column label="提交内容" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.feedbackContent}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="问题图片" prop="userId" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click.stop="download(1,scope.row)" :disabled="!scope.row.picTotal||scope.row.picTotal<1">下载</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="问题文档" prop="userId" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click.stop="download(2,scope.row)" :disabled="!scope.row.fileTotal||scope.row.fileTotal<1">下载</el-button>
                    </template>
                </el-table-column> -->
                <el-table-column label="服务客服" prop="userName" align="center"></el-table-column>
              </el-table>
        </div>
      </el-main>
      <div class="pagination">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="condition.currentPage"
            :page-sizes="[10, 15, 20, 30, 50, 100]"
            :page-size="condition.pageSize"
            layout="slot, total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </el-container>
    <el-dialog
        title="反馈详情"
        :visible.sync="dialogAdviceVisible"
        v-if="dialogAdviceVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="55%"
        height="100%"
        class="adviceTo"
      >
        <save :feedbackId="feedbackId" :userId="userId"></save>
    </el-dialog>
  </div>
</template>

<script>
import save from './Save'
export default {
    name: '',
    data(){
        return {
            condition: {
                userId:'',
                currentPage: 1,
                pageSize: 30,
            },
            tabelData:[],
            total: 0,
            selectedIds:[],
            loading:false,
            dialogAdviceVisible: false,
            feedbackId:"",
            userId:'',
            oldWidth:'',
        }
    },
    mounted(){
        this.$bus.on("closeAdviceVisible",()=>{
            this.getTaskList()
        })
        this.getTaskList()
    },
    components:{
      save,
    },
    methods: {
        headerDragend(newWidth, oldWidth, column, event){
            if(column.label=="提交内容"||column.label=="服务客服"){  // 控制只能扩大
                if(newWidth<this.oldWidth){
                    column.width=this.oldWidth
                }
            }else{  //控制只能缩小
                if(newWidth>this.oldWidth){  
                    column.width=this.oldWidth
                }
            }
            console.log(this.oldWidth,newWidth, oldWidth, column, event)
        },
        download(type,row){
            console.log(row)
            var formHtml = "<form id='downLoadForm' style='display:none' method='post' action='/api/web/feedbackFile/downloadFile'>" + 
            "<input type='hidden' name='feedbackId' value='" + row.feedbackId + "'>" +
            "<input type='hidden' name='type' value='" + type + "'>" +
            "</form>";
            var divDom = document.createElement("div");
            divDom.innerHTML = formHtml;
            document.body.appendChild(divDom);
            document.getElementById("downLoadForm").submit();
            document.body.removeChild(divDom);
        },
        seeRowDetail(row){
            this.feedbackId=row.feedbackId
            this.userId=row.userId
            this.$nextTick(()=>{
                this.dialogAdviceVisible=true
            })
        },
        selectAll(sels) {
            this.selectedIds = sels;
        },
        createAdvice(){
            this.dialogAdviceVisible=true
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 0) {
                return "warning-row";
            } else {
                return "success-row";
            }
        },
        getTaskList() {
            let formdata=new FormData()
            formdata.append('currentPage',this.condition.currentPage)
            formdata.append('pageSize',this.condition.pageSize)
            if(this.condition.userId){
              formdata.append('userId',this.condition.userId)
            }
            //查询用户拥有的记录
            this.$ajax
                .post("/api/web/feedback/selectList", formdata)
                .then((res) => {
                if (res.code == 200) {
                    this.tabelData = res.data.content;
                    this.total = res.data.totalElements;
                    this.$nextTick(()=>{
                        var that=this
                        this.oldWidth=parseInt($(".tableLimit").width()/8)
                    })
                }
                })
                .catch((err) => {
                    this.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        handleSizeChange(pageSize) {
            this.condition.pageSize = pageSize;
            this.progress = {};
            for (let i = 1; i <= pageSize; i++) {
                this.progress[i] = 0;
            }
            this.getTaskList();
        },
        handleCurrentChange(page) {
            this.condition.currentPage = page;
            this.getTaskList();
        },
    }
}
</script>

<style scoped>
.empty-head {
    height: 50px;
    border-bottom: 2px solid #eee;
    display: flex;
    align-items: center;
}
section.el-container {
    height: calc(100% - 50px);
}
.advice-container.adivceAdmin {
    padding: 0 20px;
    height: calc(100% - 127px);
    margin-top: 21px;
}
.corFileList {
    height: 100%;
}
.hedat-contnt {
    font-size: 15px;
    font-weight: 600;
}
.tableLimit{
  width: 100%;
  /* border: 1px solid #ccc; */
  max-height: 100%;
}
.el-table >>>.cell{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
}
.el-table >>>.el-table__body-wrapper {
    height: calc(100% - 70px);
    overflow-y: auto;
}
.pagination {
    position: fixed;
    left: 0px;
    bottom: 10px;
    width: 100%;
    height: 42px;
    text-align: right;
}
.el-pagination {
    margin-top: 10px;
    margin-right: 10px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #303133;
    font-weight: 700;
    text-align: right;
}
.searchUser >>>.el-input__inner{
  border-radius: 20px;
}
.adviceTo >>>.el-dialog {
    margin: 0 auto;
    margin-top: 5vh!important;
}
.el-table >>>th{
    color: rgb(76, 76, 76);
    background: #eee;
}
.el-table--border >>>td, .el-table--border >>>th, .el-table__body-wrapper >>>.el-table--border.is-scrolling-left~.el-table__fixed{
    border-right: 1px solid #dadada;
}
.el-table >>>td, .el-table >>>th.is-leaf{
    border-bottom: 1px solid #dcdcdc;
}
.el-table >>>.el-table__column-resize-proxy{
    border-left: 1px solid #dcdcdc
}

.candode {
    margin-top: 12px;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 96%;
    height: 40px;
    margin: 0;
    justify-content: space-between;
}
.el-main{
    padding: 0;
}
</style>