<template>
    <div class="div_mian">
        <div class="dataForm">
            <el-form :model="channel" :rules="rules" label-width="100px" ref="dataForm" @submit.native.prevent >
                   <el-form-item label="文件夹名" prop="folderName">
                       <el-input v-model="channel.folderName" placeholder="请输入文件夹名称" class="foldername"></el-input>
                   </el-form-item>
            </el-form>
        </div>
        <div class="dialog-footer">
            <el-button size="mini" class="fr" type="primary" @click="submitForm">确定
            </el-button>
            <el-button size="mini" class="fr" type="default" @click="abort">取消</el-button>
        </div>
    </div>
</template>
<script type="text/javascript">
  export default {
    name: 'creatfile',
    props: ['alldir'],
    data () {
      return {
        loading: false,
        channel:{
          folderName:''
        },
        rules:{
          folderName:  { required: true, message: '文件夹名称不能为空', trigger: 'blur' },
        },
      }
    }
    ,
    mounted(){
      document.querySelector(".foldername input").focus()
    },
    methods: {
      clearAll(){
        this.loading=false
        this.channel={
          folderName:''
        }
      },
      submitForm(){
        if(!this.channel.folderName){
          this.$message({
            message:"请输入文件夹名称",
            type:"warning"
          })
          return
        }
        this.$refs['dataForm'].validate(valid => {
          console.log(valid)
          if (valid) {
            if (this.loading) {
              return;
            }
            this.loading=true
            console.log(this.channel)
            var formData=new FormData()
            formData.append('folderName',this.channel.folderName)
            this.$ajax.post('/api/web/userFolder/saveUserFolder', formData).then(res => {
              if (res.code == 200) {
                this.$notify({
                  title: '提示',
                  message: '创建成功',
                  type: 'success',
                  duration: 1000,
                  customClass: 'el-notification-error'
                })
                this.$bus.emit('refreshDataFolder')
              } else {
                this.$notify({title: '提示', message: res.msg, type: 'error', duration: 1500})
              }
              this.loading= false
              })
            } else {
              this.loading= false
              console.log("error submit!!");
              return false;
            }
        });
      },
     abort () {
        // 把写的提示信心需要换行的地方分成数组 confirmText
        if(this.channel.folderName){
          this.$confirm(
            '提示', {
              message: '确认要关闭本页面?',
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then((res) => {
            this.$bus.emit('closeFolderDialog', true)
          }).catch((err) => {

          })
        }else{
          this.$bus.emit('closeFolderDialog', true)
        }
      }
    },
  }

</script>
<style type="text/css" scoped>

  .div_mian{
    width: 100%;
    height: 100%;
  }
  .dialog-footer{
    margin-top: 20px;
    height: 40px;
  }
   .dialog-footer button{
     margin-right: 10px;
   }
   .el-input{
     width: 90%;
   }
   .warning{
     margin-left: 9px;   
    }
</style>
