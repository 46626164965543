<template>
  <div class="save_main">
    <div class="user-form">
        <el-form :model="channel" :rules="rules" label-width="100px" ref="dataForm" @submit.native.prevent >
          <el-form-item label="备注信息" prop="comment">
              <el-input type="textarea" v-model="channel.comment" placeholder="请输入备注信息" class="description"></el-input>
          </el-form-item>
        </el-form>
    </div>
    <div class="dialog-footer">
      <el-button size="mini" class="fr" type="primary" @click="submitForm"
        >确定</el-button
      >
      <el-button size="mini" class="fr" type="default" @click="abort"
        >取消</el-button
      >
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import $utils from '../plugins/utils';
export default {
  name: "saveRole",
  props: ["recordRow"],
  data() {
    return {
      channel: {
        comment:''
      },
      rules:{
        comment: { required: true, message: '请输入备注信息', trigger: 'blur' },
      },
      loading: false,
    };
  },
  mounted() {
    document.querySelector(".description textarea").focus()
    if(this.recordRow){
      this.channel.comment=this.recordRow.comment
    }
  },
  watch:{
    recordRow:{
      handler(val){
        if(val){
          this.channel.comment=val.comment
        }
      },
      deep:true
    }
  },
  methods: {
    submitForm() {
      if(!this.channel.comment){
          this.$message({
              message:"请输入备注信息",
              type:'warning'
          })
          return
      }  
      if (this.loading) {
        return;
      }
      this.loading=true
      let formData = new FormData();
      formData.append("recordId", this.recordRow.recordId);
      formData.append("comment", this.channel.comment);

      this.$ajax.post("/api/web/apiRecord/saveOcrResult",formData).then(res=>{
        if(res.code==200){
          this.$notify({
            title: "提示",
            message: "修改成功",
            type: 'success',
            duration: 1000,
            customClass: "el-notification-error",
          });
          this.$bus.emit("refreshDataRecord");
          this.$bus.emit("refreshDataFolderTask");
          this.$bus.emit("refreshDataTranslate");
          this.$bus.emit("refreshDataResult");
          this.$bus.emit("refreshDataFolderTaskResult");
        } else {
          this.$notify({
            title: "提示",
            message: res.msg,
            type: "error",
            duration: 1500,
          });
        }
        this.clearAll();
      }).catch(err=>{
          this.clearAll();
      })
    },
    // 清空选择的file标签值和table列表
    clearAll() {
      this.loading=false
      this.channel={
        comment:''
      }
    },
    abort() {
      if(this.channel.comment){
        this.$confirm(
          '提示', {
            message: '确认要关闭本页面?',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then((res) => {
            this.$bus.emit("closeUpdateRecordDialog");
            this.$bus.emit("closeUpdateTranslateDialog");
            this.$bus.emit("closeUpdateRecordFolderDialog");
            this.$bus.emit("closeUpdateRecordResultDialog");
            this.$bus.emit("closeUpdateRecordResultFolderDialog");
            this.clearAll()
        }).catch((err) => {

        })
      }else{
          this.$bus.emit("closeUpdateRecordDialog");
          this.$bus.emit("closeUpdateTranslateDialog");
          this.$bus.emit("closeUpdateRecordFolderDialog");
          this.$bus.emit("closeUpdateRecordResultDialog");
          this.$bus.emit("closeUpdateRecordResultFolderDialog");
          this.clearAll()
      }
    },
  },
};
</script>
<style type="text/css" scoped>

.save_main .dialog-footer {
  margin-top: 20px;
  height: 40px;
}
</style>