<template>
  <div class="createAdvice">
    <div class="user-form">
        <el-form :model="channel" label-width="120px" ref="dataForm" @submit.native.prevent >
            <el-form-item label="反馈记录ID" prop="content">
                <p class="text">{{rowDetail.feedbackId}}</p>
            </el-form-item>
            <el-form-item label="用户ID" prop="content">
                <p class="text">{{userId}}</p>
            </el-form-item>
            <el-form-item label="提交时间" prop="content">
                <p class="text">{{rowDetail.makeTime}}</p>
            </el-form-item>
            <el-form-item label="提交内容" prop="content">
                <p class="text">{{rowDetail.feedbackContent}}</p>
            </el-form-item>
            <!-- <el-form-item label="图片" prop="content">
                <div class="imgs">
                  <div class="imgs-each" v-for="(item,i) in rowDetail.pictures" :key="i" @click="download(1,item)">
                    <img :src="item.downloadPath" alt="" class="img-left">
                    <div class="img-conten">
                      <p class="img-name">{{item.fileName}}</p>
                      <p class="img-size">{{item.fileSize}}</p>
                    </div>
                  </div>
                </div>
            </el-form-item>
            <el-form-item label="文档" prop="content" style="magin-top:24px">
                <div class="imgs">
                  <div class="imgs-each" v-for="(item,i) in rowDetail.files" :key="i" @click="download(2,item)">
                    <span class="iconfont icon-pdf img-left" style="font-size: 40px;color: #ff5a5a;line-height: 39px;"></span>
                    <div class="img-conten">
                      <p class="img-name">{{item.fileName}}</p>
                      <p class="img-size">{{item.fileSize}}</p>
                    </div>
                  </div>
                </div>
            </el-form-item> -->
            <el-form-item label="回复反馈" prop="content">
                <el-input type="textarea" class="replycontent" v-model="channel.content"></el-input>
                <p v-if="rowDetail.replyContent" class="hasreply">回复于 {{rowDetail.replyTime}}</p>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button size="mini" class="fr" type="primary" @click="submitForm"
            >提交</el-button
          >
        </div>
    </div>
    <div class="user-form" style="margin-top: 15px;">
        <el-form :model="roles" label-width="120px" ref="dataForm" @submit.native.prevent >
            <el-form-item label="奖励反馈" prop="reason" >
                <el-autocomplete style="width:100%;margin:0 auto" :disabled="rowDetail.award==1"
                  class="inline-input"
                  v-model="roles.reason"
                  :fetch-suggestions="querySearch"
                  placeholder="填写原因"
                  @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="奖励使用空间" prop="memory" style="text-align:left">
                <el-input class="replycontent" v-model="roles.memory" style="width:90%" @input="keyNumber" :readonly="rowDetail.award==1"></el-input><span style="margin-left:7px">MB</span>
            </el-form-item>
            <el-form-item label="奖励空间有效期" prop="expirationTime" style="text-align:left">
                <el-date-picker  :readonly="rowDetail.award==1"
                    v-model="roles.expirationTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    @change="dataChange">
                </el-date-picker>
                <div class="" style="margin-top: 9px">
                  <el-radio-group v-model="timeQuick" :disabled="rowDetail.award==1">
                    <el-radio label="0">一个月</el-radio>
                    <el-radio label="1">三个月</el-radio>
                    <el-radio label="2">六个月</el-radio>
                    <el-radio label="3">一年</el-radio>
                    <el-radio label="4">永久</el-radio>
                  </el-radio-group>
                </div>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button size="mini" class="fr" type="primary" @click="submitReward" :disabled="rowDetail.award==1"
            >提交奖励</el-button
          >
        </div>
        <el-dialog
            title=""
            :visible.sync="dialogConfirmVisible"
            width="30%"
            :append-to-body="true"
            class="confirmDialog"
            >
            <div class="confirm-content">
              <p class="confirm-title">请确认信息无误：</p>
              <div class="each-li">
                <div class="li-each">奖励原因：{{roles.reason}}</div>
                <div class="li-each">有效日期：{{timeQuick==4?'永久有效':ziDate}}</div>
                <div class="li-each">内存大小：{{roles.memory}}MB</div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="dialogConfirmVisible = false">取 消</el-button>
              <el-button size="small" type="primary" @click="submitForm2">确认提交</el-button>
            </span>
        </el-dialog>
    </div>
    
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
export default {
  name: "saveRole",
  props: ['feedbackId','userId'],
  data() {
    return {
      channel: {
        content:'',
      },
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;   //禁用以前的日期，今天不禁用
            // return date.getTime() <= Date.now();    //禁用今天以及以前的日期
          }
      },
      roles:{
        expirationTime:''
      },
      loading: false,
      reasons:[],
      timeQuick:'',
      dialogConfirmVisible:false,
      ziDate:'',
      rowDetail:{}
    };
  },
  created(){
    this.seeRowDetail()
    this.getWritedReason()
  },
  mounted() {
  },
  watch:{
    feedbackId(val){
      if(val){
        this.seeRowDetail()
      }
    },
    rowDetail:{
      handler(val){
        console.log(this.rowDetail)
        if(val){
          this.channel.content=val.replyContent
          this.roles={
            reason:this.rowDetail.reason,
            memory:this.rowDetail.memory,
            expirationTime:this.rowDetail.expirationTime
          }

          this.timeQuick=this.rowDetail.perpetual==1?'4':''
          this.roles.expirationTime=this.rowDetail.perpetual==1?'':this.roles.expirationTime
        }
      },
      deep:true
    },
    timeQuick(val){
      if(val){
        console.log(val)
        var data=new Date()
        var year=data.getFullYear()
        var month=data.getMonth()+1
        var date=data.getDate()
        if(val!=3&&val!=4){
          var newmonth,newyear=year
          if(val==0){
            newmonth=month+1
          }else if(val==1){
            newmonth=month+3
          }else if(val==2){
            newmonth=month+6
          }
          if(newmonth>12){
            newyear=newyear+1
            newmonth=newmonth-12
          }
          this.roles.expirationTime=newyear+"-"+newmonth+"-"+date
        }else{
          if(val==3){
            this.roles.expirationTime=(year+1)+"-"+month+"-"+date
          }else if(val==4){
            this.roles.expirationTime=''
          }
        }
      }
    },
    'roles.expirationTime'(val){
      if(val){
        var arr=val.split("-")
        this.ziDate=arr[0]+"年"+arr[1]+"月"+arr[2]+"日"
      }
    }
  },
  methods: {
      seeRowDetail(row){
          let formdata=new FormData()
          formdata.append('feedbackId',this.feedbackId)
        //查询用户拥有的记录
          this.$ajax
              .post("/api/web/feedback/findInfo", formdata)
              .then((res) => {
                  if (res.code == 200) {
                    this.rowDetail=res.data
                  }
              })
              .catch((err) => {
                  this.$message({
                      message: err,
                      type: "error",
                  });
              });
      },
      keyNumber(){
        if(this.roles.memory.length==1){
          this.roles.memory=this.roles.memory.replace(/[^1-9]/g,'')
        }else{
          this.roles.memory=this.roles.memory.replace(/\D/g,'')
        }
      },
      querySearch(queryString, cb) {
        var restaurants = this.reasons;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      },
    dataChange(){
      console.log(this.roles.expirationTime)
      this.timeQuick=''
    },
    getWritedReason(){
      this.$ajax.get("/api/web/MemoryHis/queryReasonHis").then(res=>{
        if(res.code==200){
          res.data.reasonHis.forEach(item => {
            var param={}
            param.value=item
            param.text=item
            this.reasons.push(param)
          });
        }
      })
    },
    download(type,row){
      var formHtml = "<form id='downLoadForm' style='display:none' method='post' action='/api/web/feedbackFile/downloadOneFile'>" + 
      "<input type='hidden' name='feedbackFileId' value='" + row.feedbackFileId + "'>" +
      "<input type='hidden' name='type' value='" + type + "'>" +
      "</form>";
      var divDom = document.createElement("div");
      divDom.innerHTML = formHtml;
      document.body.appendChild(divDom);
      document.getElementById("downLoadForm").submit();
      document.body.removeChild(divDom);
    },
    submitForm() {
      if(!this.channel.content){
          this.$message({
              message:"请输入反馈或建议内容",
              type:'warning'
          })
          return
      }
      if(this.rowDetail.whetherReply==1){
        this.$confirm(
          '提示', {
            message: '该反馈已回复过，是否确定要重新提交?',
            confirmButtonText: '重新提交',
            cancelButtonText: '取消',
            type: 'warning'
          }).then((res) => {
            this.submitData()
        }).catch((err) => {

        })
      }else{
        this.submitData()
      }
      
    },
    submitData(){
      if (this.loading) {
        return;
      }
      this.loading=true
      var formdata=new FormData()
      formdata.append("feedbackId",this.rowDetail.feedbackId)
      formdata.append("replyContent",this.channel.content)
      this.$ajax.post("/api/web/feedback/replyFeedback",formdata).then(res=>{
        if(res.code==200){
          this.$notify({
              title: "提示",
              message: "回复成功",
              type: 'success',
              duration: 1000,
              customClass: "el-notification-error",
          });
          this.seeRowDetail()
          this.$bus.emit("closeAdviceVisible")
        }else{
          this.$notify({
              title: "提示",
              message: res.msg,
              type: 'success',
              duration: 1000,
              customClass: "el-notification-error",
          });
        }
        this.loading=true
      }).catch(err=>{
        this.loading=true
        this.clearAll();
      })
    },
    submitReward(){
      if(!this.roles.reason){
          this.$message({
              message:"请选择奖励原因",
              type:'warning'
          })
          return
      }
      if(!this.roles.memory){
          this.$message({
              message:"请输入奖励空间",
              type:'warning'
          })
          return
      }
      if(!this.roles.expirationTime&&this.timeQuick!=4){
          this.$message({
              message:"请选择奖励空间有效期",
              type:'warning'
          })
          return
      }
      this.dialogConfirmVisible = true
    },
    submitForm2(){
      if (this.loading) {
        return;
      }
      this.loading=true
      var formdata=new FormData()
      formdata.append("feedbackId",this.rowDetail.feedbackId)
      formdata.append("reason",this.roles.reason)
      formdata.append("memory",this.roles.memory)
      formdata.append("expirationTime",this.roles.expirationTime)
      formdata.append("perpetual",this.timeQuick==4?1:0)
      this.$ajax.post("/api/web/MemoryHis/commitMemoryHis",formdata).then(res=>{
        if(res.code==200){
          this.$notify({
              title: "提示",
              message: "操作成功",
              type: 'success',
              duration: 1000,
              customClass: "el-notification-error",
          });
          this.seeRowDetail()
          this.$bus.emit("closeAdviceVisible")
          this.getWritedReason()
          this.dialogConfirmVisible = false
        }else{
          this.$notify({
              title: "提示",
              message: res.msg,
              type: 'error',
              duration: 1000,
              customClass: "el-notification-error",
          });
        }
        this.loading=true
      }).catch(err=>{
        this.loading=true
        this.clearAll();
      })
    },
    // 清空选择的file标签值和table列表
    clearAll() {
      this.loading=false
      this.channel={
        content:'',
      }
    },
  },
};
</script>
<style type="text/css" scoped>

.createAdvice .dialog-footer {
  margin-top: 20px;
  height: 40px;
  margin-left: 110px;
  text-align: left;
}
.cansee {
    font-size: 12px;
    margin-top: 50%;
    transform: translateY(-50%);
}
.el-form-item{
  margin-bottom: 15px;
}
.el-form-item >>>.el-form-item__label,.el-form-item >>>.el-form-item__content{
  line-height: 22px;
}
.el-form-item >>>.el-form-item__content{
  padding-left: 10px;
}
.text{
  text-align: left;
}
.replycontent >>>.el-textarea__inner{
  height: 100px;
}
.imgs-each {
    width: 49%;
    margin-bottom: 5px;
    background: #f6f6f6;
    padding: 5px;
    display: flex;
    align-items: center;
}
.img-left {
    display: block;
    width: 45px;
    height: 40px;
}
.img-conten {
    width: calc(100% - 45px);
    margin-left: 10px;
    text-align: left;
}
p.img-name {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.img-size {
    color: #969696;
}
.hasreply {
    text-align: left;
    padding-top: 5px;
    font-size: 13px;
    color: #a7a7a7;
}
.confirmDialog /deep/.el-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0!important;
}
.confirmDialog /deep/.el-dialog__body{
  text-align: left;
}
.confirm-content {
    line-height: 25px;
}
.each-li {
    padding-left: 50px;
}
</style>