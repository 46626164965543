<template>
  <div id="time-chats">
        <div class="empty-head"></div>
      <el-row class="keySearch">
        <el-col :span="4">
          <el-select v-model="condition.source" style="margin-left:40px" @change="queryData">
            <el-option v-for="item in sourceType" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="16" style="text-align:left">
            <el-date-picker style="width:50%;margin-left:20px"
                v-model="createDate"
                type="daterange"
                align="right"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
            </el-date-picker>
            <el-radio-group v-model="condition.timeValue" style="margin-left:20px">
              <el-radio label="1" style="margin-right:14px">当天</el-radio>
              <el-radio label="2" style="margin-right:14px">周</el-radio>
              <el-radio label="3">月</el-radio>
            </el-radio-group>
        </el-col>
      </el-row>
      <div class="time-line">
          <div class="typecount">
            <span>总数：{{typeCount}}</span>
            <span class="yesterSpan" style="margin-left: 50px;" v-show="createDate&!createDate[0]">{{yesterType}}：{{yesterTypeCount}}</span>
          </div>
          <ul class="tab-type">
            <li class="tab-each" v-for="(item,i) in types" :key="i" @click="seeOn(item,i)" :class="{'active':item==activeName}">{{item}}</li>
          </ul>
          <div id="myChartTime"></div>
      </div>
  </div>

  
</template>
<script>
var elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: 'home',
  data () {
    return {
        condition: {
          timeValue:'1',
          source:'0'
        },
        createDate: ['',''],
        pidToNameMap:{1001:"通用文字识别",1034:"手写识别",2:"身份证",4:"银行卡",8:"护照",1013:"港澳通行证",1002:"户口本",53:"外国人永久居留证",
          1004:"结婚证",1005:"离婚证",11:"军官证",52:"出生证",1039:"社保卡",7:"驾驶证",5:"行驶证",1:"VIN码",62:"机动车登记证",68:"机动车合格证",21:"增值税发票",
          83:"出租车票",84:"定额发票",66:"火车票",82:"行程单",1031:"电子承兑汇票",61:"机动车发票",60:"二手车发票",41:"营业执照",1015:"开户许可证",1033:"全国各省市房产证"},
        types:["用户打开数", "新增用户数", "活跃用户数", "产品使用数"],
        sourceType:[
          {name:'全部',value:'0'},
          {name:'小程序',value:'1'},
          {name:'web',value:'2'},
        ],
        translateMap:{ 1:'图片转PDF',2:'图片转Word',3:'PDF转Word',4:'截图转Word',5:'PDF转Excel',6:'PDF拆分'},
        timer:'',
        picType:[],
        picMap:{},
        picData:[],
        picTitle:"",
        modelMap:[],
        dateArr:[],
        activeName:'用户打开数',
        drawType:'line',
        xBoundaryGap:false,
        tooltipShow:false,
        typeCount:0,
        yesterType:'昨天',
        yesterTypeCount:0
    }
  },
  created(){
      console.log('created')
      // this.getKindsType('用户打开数')
  },
  mounted(){
    // this.timer = setInterval(this.initData, 10000);
    let that = this;
    //组件宽度发生变化重新渲染定位
    var timer=''
    this.$nextTick(()=> {
        this.erd = elementResizeDetectorMaker();
        this.erd.listenTo(document.getElementById("time-chats"), function(element) {
          that.$nextTick(function() {
              console.log('listen')
              var dom = document.getElementById('myChartTime')
              dom.removeAttribute('_echarts_instance_'); 
              that.getKindsType(that.activeName)
          });
        });
    })
  },
  beforeDestroy(){
      if(this.timer){
         clearInterval(this.timer);
      }
  },
  watch:{
    createDate:{
      handler(val){
        if(val){
          if(val[0]){
            this.condition.timeValue=''
            this.queryData()
          }
        }else{
          this.createDate=['','']
        }
      },
      deep:true
    },
    'condition.timeValue'(val){
      if(val){
        if(this.condition.timeValue==1){  // 日
          this.yesterType="昨天"
        }else if(this.condition.timeValue==2){  // 周
          this.yesterType="上周"
        }else if(this.condition.timeValue==3){  // 月
          this.yesterType="上月"
        }
        this.createDate=['','']
        this.queryData()
      }
    }
  },
  methods: {
    queryData(){
      var dom = document.getElementById('myChartTime')
      dom.removeAttribute('_echarts_instance_'); 
      this.getKindsType(this.activeName)
    },
    seeOn(name,index){
      this.activeName=name
      $(".tab-each").removeClass("active").eq(index).addClass("active")
      var dom = document.getElementById('myChartTime')
      dom.removeAttribute('_echarts_instance_'); 
      this.getKindsType(name)
    },
    //获取两日期之间日期列表函数
    getdiffdate(stime,etime){
        //初始化日期列表，数组
        var diffdate = new Array();
        var i=0;
        //开始日期小于等于结束日期,并循环
        while(stime<=etime){
            diffdate[i] = stime;
            //获取开始日期时间戳
            var stime_ts = new Date(stime).getTime();
            console.log('当前日期：'+stime   +'当前时间戳：'+stime_ts);
            //增加一天时间戳后的日期
            var next_date = stime_ts + (24*60*60*1000);
            //拼接年月日，这里的月份会返回（0-11），所以要+1
            var next_dates_y = new Date(next_date).getFullYear()+'-';
            var next_dates_m = (new Date(next_date).getMonth()+1 < 10)?'0'+(new Date(next_date).getMonth()+1)+'-':(new Date(next_date).getMonth()+1)+'-';
            var next_dates_d = (new Date(next_date).getDate() < 10)?'0'+new Date(next_date).getDate():new Date(next_date).getDate();
            stime = next_dates_y+next_dates_m+next_dates_d;
            //增加数组key
            i++;
        }
        return diffdate
    },
    // 各类型数据
    getKindsType(name){
      var url=''
      if(name=="用户打开数"){
        url='/api/web/userOpen/getUserOpen'
      }else if(name=="新增用户数"){
        url='/api/web/userNewlyIncreased/find'
      }else if(name=="活跃用户数"){
        url='/api/web/userOperation/getUserOperation'
      }else if(name=="产品使用数"){
        url='/api/web/productUseLog/getProductUseLog'
      }
      if(name=="产品使用数"){
        this.drawType="bar"
        this.xBoundaryGap=true
        this.tooltipShow=true
      }else{
        this.drawType="line"
        this.xBoundaryGap=false
        this.tooltipShow=false
      }
      var formdata=new FormData()
      if(this.createDate&&this.createDate[0]){
        formdata.append("startTime",this.createDate[0])
        formdata.append("endTime",this.createDate[1])
      }
      formdata.append("timeValue",this.condition.timeValue)
      formdata.append("source",this.condition.source)
      this.$ajax.post(url,formdata).then((res) => {
            if(res.code==200){
              this.yesterTypeCount=res.data.count
              var count=0
              this.dateArr=[]
              this.picData=[]
              if(name!="产品使用数"){
                if(this.condition.timeValue==1){  // 日
                  this.dateArr=['00：00','01：00','02：00','03：00','04：00','05：00','06：00','07：00','08：00','09：00','10：00','11：00',
                    '12：00','13：00','14：00','15：00','16：00','17：00','18：00','19：00','20：00','21：00','22：00','23：00']
                }else if(this.condition.timeValue==2){  // 周
                  this.dateArr=['周一','周二','周三','周四','周五','周六','周日']                
                }else if(this.condition.timeValue==3){  // 月
                  var now = new Date(); // 当前日期
                  var nowMonth = now.getMonth()+1; // 当前月
                  var nowYear = now.getFullYear(); // 当前年
                  //本月的结束时间
                  var monthEndDate = new Date(nowYear, nowMonth, 0)
                  nowMonth=nowMonth<10?'0'+nowMonth:nowMonth
                  monthEndDate=monthEndDate<10?'0'+monthEndDate:monthEndDate
                  console.log(monthEndDate)
                  this.dateArr=this.getdiffdate(nowYear+"-"+nowMonth+"-01",nowYear+"-"+nowMonth+"-"+monthEndDate)
                }else if(this.createDate&&this.createDate[0]){
                  this.dateArr=this.getdiffdate(this.createDate[0],this.createDate[1])
                }
                // console.log(this.dateArr)
                var dataMap={}
                if(this.condition.timeValue==1){  // 日
                  res.data.results.forEach(item=>{
                    count=count+item.count
                    item.time=item.time+"：00"
                    dataMap[item.time]=item.count
                  })
                }else if(this.condition.timeValue==2){  // 周
                  res.data.results.forEach(item=>{
                    count=count+item.count
                    var day=new Date(item.time).getDay()
                    if(day==0){
                      day=7
                    }
                    dataMap[this.dateArr[day-1]]=item.count
                  })
                }else if(this.condition.timeValue==3||(this.createDate&&this.createDate[0])){
                  res.data.results.forEach(item=>{
                    count=count+item.count
                    dataMap[item.time]=item.count
                  })
                }
                this.dateArr.forEach((it,i) => {
                  if(dataMap[it]){
                    this.picData.push(dataMap[it])
                  }else{
                    this.picData.push(0)
                  }
                });
              }else{
                var pidMap={}
                for (const key in this.translateMap) {
                  pidMap[this.translateMap[key]]=0
                  this.dateArr.push(this.translateMap[key])
                }
                for (const key in this.pidToNameMap) {
                  pidMap[this.pidToNameMap[key]]=0
                  this.dateArr.push(this.pidToNameMap[key])
                }
                res.data.results.forEach(item=>{
                  count=count+item.count
                  if(item.taskType==0){
                    pidMap[this.pidToNameMap[item.pid]]=item.count
                  }else{
                    pidMap[this.translateMap[item.taskType]]=item.count
                  }
                })
                for (const key in pidMap) {
                  this.picData.push(pidMap[key])
                }
              }
              this.typeCount=count
              // console.log(this.dateArr,this.picData)
              this.drawLine()
            }
        }).catch(err=>{
          console.log(err)
        })
    },
    drawLine(){
      //渲染折线图
      var that=this
      let myChart = this.$echarts.init(document.getElementById("myChartTime"))
      let option = {
          tooltip: {
              // show:this.tooltipShow,
              trigger: 'axis',
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                  type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function (params) {
                var now = new Date(),str=''
                var nowYear = now.getFullYear();
                var nowMonth = now.getMonth()+1;
                var date = now.getDate();
                // console.log(params)s
                if(that.condition.timeValue==1){  // 日
                  str = nowYear+"-"+(nowMonth<10?('0'+nowMonth):nowMonth)+"-"+(date<10?('0'+date):date)+" "+params[0].axisValue + "<br />";
                }else{
                  str = params[0].axisValue + "<br />";
                }
                params.forEach((item) => {
                    str +=
                      '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + that.activeName+"：" + item.data + "<br />";
                  });
                return str;
              },
          },
          grid:{
              top:"50px",
              left:"5%",
              right:"5%",
              bottom:"90px"
          },
          xAxis: {
              type: 'category',
              boundaryGap: this.xBoundaryGap,
              data: this.dateArr,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {  
                // interval:0,  
                rotate:-45
              } 
          },
          yAxis: {
              type: 'value',
              minInterval: 1
          },
          series: [{
              data: this.picData,
              type: this.drawType,
              smooth: true,
              itemStyle:{
                color: "rgba(0, 145, 249, 1)",
              },
              barWidth :25,
              barCategoryGap: "5%"
          }]
        };
        myChart.setOption(option,true);
      }  
    }
}
</script>
<style scoped>
.empty-head {
    height: 50px;
    border-bottom: 2px solid #eee;
    display: flex;
    align-items: center;
}
.time-now {
    width: 100%;
    text-align: left;
    padding-left: 60px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}
.kinds-type {
    height: 100px;
    border-bottom: 20px solid #F4F4F4;
}
.kinds-each-col {
    height: 100%;
    border-right: 1px solid #ddd;
    line-height: 32px;
}
.conten-type {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #555;
}
.type-title {
    font-size: 14px;
}
.type-count {
    font-size: 16px;
    font-weight: 550;
}
.keySearch.el-row {
    display: flex;
    align-items: center;
    margin-top: 27px;
}
#time-chats{
  height: 100%;
}
.time-line {
  width: 100%;
  height: calc(100% - 155px);
  overflow: auto;
  position: relative;
  margin-top: 35px;
}
.tab-type {
    position: absolute;
    right: 13px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color:#9e9e9e;
    z-index: 30;
}
.tab-each {
    position: relative;
    margin-right: 15px;
    cursor: pointer;
}
.tab-each.active {
    font-size: 14px;
    color: #000000;
}
.tab-each.active::after {
    content: '';
    display: block;
    bottom: -9px;
    width: 71px;
    height: 2px;
    background: #409eff;
    position: absolute;
}
.typecount {
    position: absolute;
    left: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #1b1b1b;
    z-index: 30;
}
#myChartTime{
  width: 99%;
  height: calc(100% );
  min-width: 1000px;
  margin-top: 20px;
  margin:  0 auto;
}
</style>