<template>
  <div class="save_main">
    <div class="user-form">
        <el-form :model="channel" label-width="100px" ref="dataForm" @submit.native.prevent >
          <el-form-item label="想要识别" v-if="taskType==0">
              <el-select v-model="channel.pid" placeholder="请选择识别类型" style="width:100%">
                <el-option v-for="(val,key,i) in pidTypesMap" :key="i" :label="key" :value="val" @click.native="choosePid(key,val)"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="任务名称">
              <el-input v-model="channel.taskName" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="任务描述">
              <el-input type="textarea" v-model="channel.taskDescription" placeholder="请输入任务描述"></el-input>
          </el-form-item>
          <el-upload
                  class="upload-demo"
                  ref="upload-demo"
                  drag
                  :auto-upload="false"
                  action="void"
                  :accept="acceptTypes"
                  :multiple="true"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :before-upload="beforeAvatarUpload"
                  :file-list="fileList"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    <em>点击</em>或将标准文件拖拽到这里上传
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    支持扩展名{{acceptTypesIntro}}，文件总大小不超过50MB
                  </div>
            </el-upload>
          </el-form>
    </div>
    <div class="dialog-footer">
      <el-button size="mini" class="fr" type="primary" @click="submitForm"
        >上传</el-button
      >
      <el-button size="mini" class="fr" type="default" @click="abort"
        >取消</el-button
      >
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import $utils from '../plugins/utils';
// import kscreenshot from 'kscreenshot'
export default {
  name: "saveRole",
  props: ["folderId","taskType"],
  data() {
    return {
      channel: {
        pid: 1001,
        taskName:'',
        taskDescription:''
      },
      loading: false,
      acceptTypes:'',
      acceptTypesIntro:'',
      uploadRate: 0,
      fileList:[],
      pidTypesMap:{"通用文字识别":1001,"手写识别":1034,"身份证":2,"银行卡":4,"护照":8,"港澳通行证":1013,"户口本":1002,"外国人永久居留证":53,
      "结婚证":1004,"离婚证":1005,"军官证":11,"出生证":52,"社保卡":1039,"驾驶证":7,"行驶证":5,"VIN码":1,"机动车登记证":62,"机动车合格证":68,"增值税发票":21,
      "出租车票":83,"定额发票":84,"火车票":66,"行程单":82,"电子承兑汇票":1031,"机动车发票":61,"二手车发票":60,"营业执照":41,"开户许可证":1015,"全国各省市房产证":1033},
      // pidTypesVerseMap:{1001:"通用文字识别",1034:"手写识别",2:"身份证",4:"银行卡",8:"护照",1013:"港澳通行证",1002:"户口本",53:"外国人永久居留证",
      // 1004:"结婚证",1005:"离婚证",11:"军官证",52:"出生证",1039:"社保卡",7:"驾驶证",5:"行驶证",1:"VIN码",62:"机动车登记证",68:"机动车合格证",21:"增值税发票",
      // 83:"出租车票",84:"定额发票",66:"火车票",82:"行程单",1031:"电子承兑汇票",61:"机动车发票",60:"二手车发票",41:"营业执照",1015:"开户许可证",1033:"全国各省市房产证"},
      taskTypesMap:{0: "识别",1:"图片转PDF",2:"图片转Word",3:"PDF转Word",4:"截图->PDF",5:'PDF转Excel',6:'PDF拆分'},
    };
  },
  mounted() {
    console.log(this.taskType)
    if(this.taskType==0||this.taskType==1||this.taskType==2||this.taskType==4){
      this.acceptTypes="image/jpg,image/png,image/jpeg"
      this.acceptTypesIntro='.jpg,.png,.jpeg'
    }else{
      this.acceptTypes=".pdf,.Pdf,.PDf,.PdF,.PDF,.pDf,.pDF,.pdF"
      this.acceptTypesIntro='.pdf'
    }
    this.getMorenTaskName("通用文字识别",1001)
  },
  watch:{
    taskType(newVal){
      if(newVal==0||newVal==1||newVal==2||newVal==4){
        this.acceptTypes="image/jpg,image/png,image/jpeg"
        this.acceptTypesIntro='.jpg,.png,.jpeg'
      }else{
        this.acceptTypes=".pdf,.Pdf,.PDf,.PdF,.PDF,.pDf,.pDF,.pdF"
        this.acceptTypesIntro='.pdf'
      } 
      this.getMorenTaskName("通用文字识别",1001)
    },
  },
  methods: {
    choosePid(key,val){
      console.log(key,val)
      this.getMorenTaskName(key,val)
    },
    getMorenTaskName(key,val){
      let date=new Date()
      let year=date.getFullYear()
      let month=Number(date.getMonth())+1<10?'0'+(Number(date.getMonth())+1):Number(date.getMonth())+1
      let day=date.getDate()<10?'0'+date.getDate():date.getDate()
      let hour=date.getHours()<10?'0'+date.getHours():date.getHours()
      let minute=date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()
      let second=date.getSeconds()<10?'0'+date.getSeconds():date.getSeconds()
      let nowDate=year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second
      if(this.taskType==0){
        this.channel.taskName=key+"-"+this.taskTypesMap[this.taskType]+" "+nowDate
      }else{
        this.channel.taskName=this.taskTypesMap[this.taskType]+" "+nowDate
      }
    },
    handleChange(file, fileList) {
      console.log(fileList)
      this.fileList = fileList
    },
    handleRemove(file, fileList) {
      this.fileList=fileList
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$notify({
          title: "提示",
          message: "上传文件的大小不能超过50MB!",
          type: "error",
          duration: 2000,
        });
      }
      return isLt2M;
    },
    submitForm() {
      if(!this.channel.taskName){
          this.$message({
              message:"请输入任务名称",
              type:'warning'
          })
          return
      }
      if(this.fileList.length==0){
          this.$message({
              message:"请选择文件",
              type:'warning'
          })
          return
      }
      if (this.loading) {
        return;
      }
      this.loading=true
      
      var loadings = this.$loading({
          lock: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });
      let formData = new FormData();
      this.fileList.forEach((item, i) => {
        formData.append("files", item.raw);
      });
      if(this.taskType!=0){
        this.channel.pid=''
      }
      if(this.channel.pid){
        formData.append("pid", this.channel.pid);
      }
      console.log(this.folderId)
      if(this.folderId){
        formData.append("folderId", this.folderId);
      }
      formData.append("taskName", this.channel.taskName);
      formData.append("taskDescription", this.channel.taskDescription);
      formData.append("taskType", this.taskType);
     
      let headers = Object.assign({
        'Content-Type': 'application/json'
      })
      let token = $utils.getSessionStorage('token')
      if (token) {
          headers.accessToken = token
      }
      axios({
          method: 'post',
          url: "/api/web/ocr/ocrRecognition",
          data: formData,
          headers: headers,
          timeout:300000,
          onUploadProgress: e => {
            this.uploadRate = e.loaded / e.total *100 | 0;  //百分比
            document.querySelector(".el-loading-text").innerHTML="上传中  "+this.uploadRate+"%"
          }
      })
      .then(res => {
        loadings.close()
        if (res.data.code == 200) {
          this.$notify({
            title: "提示",
            message: "创建成功",
            type: 'success',
            duration: 1500,
            customClass: "el-notification-error",
          });
          this.$bus.emit("refreshDataRecord");
          this.$bus.emit("refreshDataFolder");
          this.$bus.emit("refreshDataFolderTask");
          this.$bus.emit("refreshDataTranslate");
          this.$bus.emit("refreshDataFolderTaskResult");
        } else {
          this.$notify({
            title: "提示",
            message: res.data.msg,
            type: "error",
            duration: 1500,
          });
        }
        this.clearAll();
      }).catch(err=>{
        loadings.close()
        this.clearAll();
      })
    },
    // 清空选择的file标签值和table列表
    clearAll() {
      this.loading=false
      this.channel={
        taskName:'',
        taskDescription:''
      }
      this.uploadRate= 0
      this.fileList=[]
      this.$refs["upload-demo"].clearFiles()
    },
    abort() {
      this.$confirm(
        '提示', {
          message: '确认要关闭本页面?',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.$bus.emit("closeTaskFileRecordDialog");
          this.$bus.emit("closeTaskFileFolderDialog")
          this.$bus.emit("closeTaskFileFolderTaskDialog")
          this.$bus.emit("closeTaskFileTranslateDialog")
          this.$bus.emit("closeTaskFileResultFolderTaskDialog")
          this.clearAll()
      }).catch((err) => {

      })
    },
  },
};
</script>
<style type="text/css" scoped>

.save_main .dialog-footer {
  margin-top: 20px;
  height: 40px;
}

</style>