<template>
  <div class="container">
    <div class="header-do" v-show="selectedFolderIds.length==0" v-if="folderList">
        <el-button type="primary" class="createCgon" @click="createTask(0)" size="small">
          <img src="../../assets/image/add.png" alt="" class="btn-img addtask">
          <span>开始识别</span> 
        </el-button>
        <el-button class="createCgon" @click="addFolder" size="small" style="font-weight: 600">
          <img src="../../assets/image/addFolder.png" alt="" class="btn-img">
          <span>新建文件夹</span>
        </el-button>
    </div>
    <div class="header-do checkeone" v-show="selectedFolderIds.length>0" v-if="folderList">
        <el-button class="createCgon" @click="commandClick('',3)" size="small" style="font-weight: 600">
          <img src="../../assets/image/word.png" alt="" class="btn-img">
          <span>导出Word</span> 
        </el-button>
        <el-button class="createCgon" @click="commandClick('',2)" v-if="!isWordExport" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/execl.png" alt="" class="btn-img">
          <span>导出Excel</span>
        </el-button>
        <el-button class="createCgon" @click="commandClick('',1)" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/pdf.png" alt="" class="btn-img">
          <span>导出PDF</span>
        </el-button>
        <el-button class="createCgon" @click="deleteSelected" size="small" style="font-weight: 600;margin-left:12px">
          <img src="../../assets/image/delete.png" alt="" class="btn-img">
          <span>删除</span>
        </el-button>
    </div>
    <div v-if="folderList" class="heights">
      <el-container>
        <el-main>
          <div class="does">
            <div class="createAll" v-if="false">
                <el-select style="width:102px;margin-top: 10px;"
                v-model="pid"
                placeholder="证卡类型"
                >
                    <el-option v-for="(val,key,index) in pidTypesMap" :label="key" :value="val" :key="index"></el-option>
                </el-select>
                    <div class="type-task" @click="createTask(0)">
                      <span class="taskicon iconfont icon-wenbenshibie"></span>
                      <span>识别</span>
                    </div>
                    <div class="type-task" @click="createTask(1)">
                      <span class="taskicon iconfont icon-xiangyou"></span>
                      <span>图片->PDF</span>
                    </div>
                    <div class="type-task" @click="createTask(2)">
                      <span class="taskicon iconfont icon-xiangyou"></span>
                      <span>图片->Word</span>
                    </div>
                    <div class="type-task" @click="createTask(3)">
                      <span class="taskicon iconfont icon-xiangyou"></span>
                      <span>PDF->Word</span>
                    </div>
                    <div class="type-task" @click="createTask(4)" v-show="false">
                      <span class="taskicon iconfont icon-xiangyou"></span>
                      <span>截图->PDF</span>
                    </div>
              </div>
            <el-row style="margin-top:20px" v-if="false">
                <el-col :span="4">
                    <el-button
                        type="primary"
                        size="small"
                        @click="deleteSelected"
                        >批量删除</el-button
                    >
                </el-col>
                <el-col :span="3" :offset="1">
                    <el-input v-model="condition.folderName" placeholder="文件夹名称"></el-input>
                </el-col>
                <el-col :span="2" :offset="1">
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="getFolderList"
                        >查询</el-button
                    >
                </el-col>
            </el-row>
            <div style="margin-top:10px" class="candode">
              <div style="margin-left: 34px">
                  <p class="hedat-contnt">我的文件夹</p>
              </div>
              <div style="width: 17%">
                <el-input class="searchUser sousuoicon" v-model="condition.folderName" placeholder="搜索文件夹" prefix-icon="iconfont icon-sousuo" @keyup.native.enter="getFolderList"></el-input>
              </div>
            </div>
          </div>
          <div class="floders-container floders">
            
            <ul class="sort-folder" v-if="false">
              <li class="sort-method on" @click="sortMethod(0,$event)">综合排序</li>
              <li class="sort-method" @click="sortMethod(1,$event)">
                <span>记录数量</span> 
                <span class="caret-wrapper">
                  <i class="sort-caret ascending" @click.stop.prevent="upToDown(1,0,$event)" title="降序"></i>
                  <i class="sort-caret descending" @click.stop.prevent="upToDown(1,1,$event)" title="升序"></i>
                </span>
              </li>
              <li class="sort-method" @click="sortMethod(2,$event)">
                <span>创建时间</span> 
                <span class="caret-wrapper">
                  <i class="sort-caret ascending" @click.stop.prevent="upToDown(2,0,$event)" title="降序"></i>
                  <i class="sort-caret descending" @click.stop.prevent="upToDown(2,1,$event)" title="升序"></i>
                </span>
              </li>
            </ul>
            <div class="flodersList">
                <div class="floder-each" v-if="false">
                  <div class="floders-add" @click="addFolder">
                      + 新增文件夹
                  </div>
                  <div class="each-floder" v-for="(item,index) in tabelData" :key="index" @click="enterTak(item.folderId)" :folderId="item.folderId">
                    <div class="each-content">
                      <p class="floder-header">
                        <span class="floder-icon iconfont icon-wenjianjia"></span>
                        <span class="floder-name">{{item.folderName}}</span>
                      </p>
                      <p class="floder-introduce">默认保存识别记录路径</p>
                      <p class="pictureNum">记录数量：<span style="color:#409eff">{{item.pictureNum}}</span></p>
                    </div>
                    <p class="createTime">创建时间：<span style="color:#409eff">{{item.makeTime}}</span></p>
                    <el-checkbox class="content-do" v-model="item.ischecked" @click.native.stop="checked(item,index)" @change="checkBox(item,index)"></el-checkbox>
                  </div>
                </div>
                <el-table
                class="foldertableLimit"
                ref="foldertableLimit"
                :data="tabelData"
                style="width: 100%;height:100%"
                @selection-change="selectAll"
                @sort-change="sortChange"
              >
                <el-table-column type="selection" width="70" ></el-table-column>
                
                <el-table-column label="文件夹名称" class-name="folderName" :sortable="'custom'">
                  <template slot-scope="scope">
                    <div class="floder-div" @click="enterTak(scope.row)">
                      <img src="../../assets/image/folder.png" alt="" class="folder-icon">
                      <span>{{scope.row.folderName}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column sortable class-name="pictureNum"
                  prop="pictureNum"
                  label="记录数量"
                >	
                </el-table-column>
                
                <el-table-column sortable class-name="makeTime"
                  prop="makeTime"
                  label="创建时间"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-main>
      </el-container>
      
      <el-dialog
        title="创建文件夹"
        :visible.sync="dialogFloderVisible"
        v-if="dialogFloderVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="50%"
        height="100%"
        @close="clearCreateFolder"
      > 
        <create-file ref="createFile"></create-file>
      </el-dialog>
      <el-dialog
        title="创建识别任务"
        :visible.sync="dialogTaskFileVisible"
        v-if="dialogTaskFileVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="55%"
        height="100%"
        class="ocrTask"
        @close="clearTaskFile"
      >
        <task-file ref="taskFile" :taskType="taskType" :pid="taskPid" :folderId="folderId"></task-file>
    </el-dialog>
    </div>
    <div class="heights tasklist" v-if="!folderList">
      <task-list :enterFolderRow="enterFolderRow" ref="taskList"></task-list>
    </div>
  </div>
</template>

<script type="text/javascript">
// let Base64 = require('js-base64').Base64
import createFile from './Creatfile'
import taskList from './task'
import taskFile from '../../components/creatTask'
export default {
  name: "myFolder",
  components: {
    taskFile,
    createFile,
    taskList
  },
  data() {
    return {
      dialogFloderVisible:false,
      dialogTaskFileVisible:false,
      folderList:true,
      folderId:'',
      progress: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
      createDate: ["", ""],
      condition: {
        currentPage: 1,
        pageSize: 10,
        folderName:''
      },
      pidTypesMap:{"通用文字识别":1001,"手写识别":1034,"身份证":2,"银行卡":4,"护照":8,"港澳通行证":1013,"户口本":1002,"外国人永久居留证":53,
      "结婚证":1004,"离婚证":1005,"军官证":11,"出生证":52,"社保卡":1039,"驾驶证":7,"行驶证":5,"VIN码":1,"机动车登记证":62,"机动车合格证":68,"增值税发票":21,
      "出租车票":83,"定额发票":84,"火车票":66,"行程单":82,"电子承兑汇票":1031,"机动车发票":61,"二手车发票":60,"营业执照":41,"开户许可证":1015,"全国各省市房产证":1033},
      pid:1001,
      taskType:'',
      taskPid:'',
      total: 0,
      tabelData: [],
      beforeTableData:[],
      selectedFolderIds: [],
      loading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      enterFolderRow:'',
      isWordExport:false   // 是否是文字导出
    };
  },
  computed:{
    enterFolderId(){
      return this.$store.state.pageParam.folderId
    }
  },
  mounted() {
    this.getFolderList(this.enterFolderId)
    let that = this;
    this.$bus.on("closeTaskFileFolderDialog", () => {
      this.dialogTaskFileVisible = false;
    });
    this.$bus.on("refreshDataFolder", () => {
      this.dialogFloderVisible=false
      this.dialogTaskFileVisible=false
      this.getFolderList()
    });
    this.$bus.on("closeTask", () => {
      this.folderList=true
    });
    this.$bus.on("closeFolderDialog", () => {
      this.dialogFloderVisible=false
    });
  },
  beforeDestroy() {
    this.$bus.off("refreshDataFolder")
    this.$bus.off("closeTask")
    this.$bus.off("closeFolderDialog")
    this.$bus.off("closeTaskFileFolderDialog")
  },
  watch:{
    tabelData:{
      handler(newVal){
        if(newVal&&newVal.length>0){
          newVal.forEach(item=>{
            if(item.folderName=="默认文件夹"){
              this.folderId=item.folderId
            }
          })
        }else{
          this.folderId=''
        }
      },
      deep:true
    }
  },
  methods: {
    sortChange(column){
      console.log(column)  //名称排序
      var sortingType = column.order;
      if(sortingType == "descending"){
        this.tabelData = this.tabelData.sort((a, b) =>b.folderName.localeCompare(a.folderName));
      }
      else if(sortingType == "ascending"){
        this.tabelData = this.tabelData.sort((a, b) => a.folderName.localeCompare(b.folderName));
      }
      $(".foldertableLimit th").css({
        fontWeight: 500,
        color: '#313131'
      })
      $(`.foldertableLimit th.${column.column.className}`).css({
        color:'#000',
        fontWeight:600
      })
      if(!sortingType){
        $(".foldertableLimit th").css({
          fontWeight: 500,
          color: '#313131'
        })
      }
      // console.log(this.tabelData);
    },
    // 导出 单个|批量
    commandClick(row,command){
      console.log(row)
      var param={}
      if(command){
        param.exportType=command
      }
      if(this.selectedFolderIds.length==0){
        this.$message({
          message:"请选择数据",
          type:'warning'
        })
        return
      }
      param.folderIds=this.selectedFolderIds.map((item)=> item.folderId)
      console.log(param)
      this.$ajax.post("/api/web/apiRecord/exportFolderResult",JSON.stringify(param)).then(res=>{
        if(res.code==200){
          if(res.data&&res.data.length>0){
            this.exportDown(res.data,command)
          }else{
            this.$notify({
              title: "提示",
              message: '没有可以导出的识别任务',
              type: "error",
              duration: 1500,
              customClass: "el-notification-error",
            }); 
          }
        }else{
         this.$notify({
            title: "提示",
            message: res.msg,
            type: "error",
            duration: 1500,
            customClass: "el-notification-error",
          }); 
        }
      })
    },
    exportDown(recordIds,exportType){
      var formHtml = "<form id='downLoadForm' style='display:none' method='post' action='/api/web/apiRecord/downloadRecordFile'>" + 
      "<input type='hidden' name='recordIds' value='" + JSON.stringify(recordIds) + "'>" +
      "<input type='hidden' name='exportType' value='" + exportType + "'>" +
      "</form>";
      var divDom = document.createElement("div");
      divDom.innerHTML = formHtml;
      document.body.appendChild(divDom);
      document.getElementById("downLoadForm").submit();
      document.body.removeChild(divDom);
    },
    selectAll(sels) {
      this.selectedFolderIds = sels;
      this.isWordExport=this.selectedFolderIds.every(item=>item.pid==1001||item.pid==1034)
    },
    sortMethod(method,e){
      $(".sort-method").removeClass("on").eq(method).addClass("on")
      $(".sort-caret").removeClass("on")
      let dom=e.target
      while(dom.tagName!='LI'){
        dom=dom.parentNode
      }
      if(method==0){
        this.tabelData=JSON.parse(JSON.stringify(this.beforeTableData))
      }
      if((method==1||method==2)&&!($(dom).find(".sort-caret").hasClass("on"))){
        $(dom).find(".sort-caret").eq(0).addClass("on")
        let newTableData=[]
        if(method==1){
          this.tabelData.sort(
            function(a, b){
              return Number(a.pictureNum) - Number(b.pictureNum)
            }
          ); 
        }
        if(method==2){
          this.tabelData.sort(
            function(a, b){
              let prev=new Date(a.makeTime).getTime()
              let next=new Date(b.makeTime).getTime()
              return Number(prev) - Number(next)
            }
          ); 
        }
      }
    },
    upToDown(method,how,e){
      $(".sort-method").removeClass("on").eq(method).addClass("on")
      $(".sort-caret").removeClass("on")
      $(e.target).addClass("on")
      if(method==1){
        if(how==0){
          this.tabelData.sort(
            function(a, b){
              return Number(a.pictureNum) - Number(b.pictureNum)
            }
          ); 
        }else{
          this.tabelData.sort(
            function(a, b){
              return  Number(b.pictureNum) - Number(a.pictureNum)
            }
          );
        }
      }else if(method==2){
        if(how==0){
          this.tabelData.sort(
            function(a, b){
              let prev=new Date(a.makeTime).getTime()
              let next=new Date(b.makeTime).getTime()
              return Number(prev) - Number(next)
            }
          ); 
        }else{
          this.tabelData.sort(
            function(a, b){
              let prev=new Date(a.makeTime).getTime()
              let next=new Date(b.makeTime).getTime()
              return Number(next) - Number(prev)
            }
          );
        }
      }
    },
    checkBox(row,index){
      this.$set(this.tabelData,index,row)
    },
    checked(row,index){
      console.log(row)
    },
    clearCreateFolder(){
      this.$refs.createFile.clearAll()
    },
    createTask(taskType){
      this.taskType=taskType
      if(taskType==0){
        if(this.pid!=''&&this.pid){
          this.taskPid=this.pid
        }else{
          this.$message({message:'请选择证卡类型',type: 'warning'})
          return
        }
      }else{
        this.taskPid=''
      }
      this.$nextTick(()=>{
        this.dialogTaskFileVisible=true
      })
    },
    enterTak(row){
      this.enterFolderRow=row
      this.$nextTick(()=>{
        this.folderList=false
      })
    },
    addFolder(){
      this.dialogFloderVisible=true
    },
    deleteSelected() {
      var ids = []
      ids=this.selectedFolderIds.map(item => {
        return item.folderId
      });
      if(ids.length==0){
        this.$message({message:'请选择文件夹',type: 'warning'})
        return
      }
      this.$confirm(
        '提示', {
          message: '确认执行删除操作？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.loading = true;
          console.log(ids)
          this.$ajax.post("/api/web/userFolder/deleteFolder",JSON.stringify({folderIds:ids})).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "提示",
                message: "删除成功",
                type: "success",
                duration: 1500,
                customClass: "el-notification-error",
              });
              this.loading = false;
              this.getFolderList();
            } else {
              this.$notify({
                title: "提示",
                message: res.msg,
                type: "error",
                duration: 1500,
              });
              this.loading = false;
            }
          });
        }).catch((err) => {

      })
    },
    getFolderList(oldfolderId) {
      this.loading = true;
      //查询用户拥有的记录
      console.log(this.condition.folderName)
      var formData=new FormData()
      formData.append("folderName",this.condition.folderName)
      formData.append("currentPage",this.condition.currentPage)
      formData.append("pageSize",this.condition.pageSize)
      this.$ajax
        .post("/api/web/userFolder/getUserFolderList", formData)
        .then((res) => {
          if (res.code == 200) {
            this.tabelData = res.data.content;
            this.beforeTableData=JSON.parse(JSON.stringify(res.data.content))
            this.total = res.data.totalElements;
            this.loading = false;
            if(res.data.content&&res.data.content.length>0){
              res.data.content.forEach(item=>{
                if(item.folderName=="默认文件夹"){
                  this.folderId=item.folderId
                }
              })
            }else{
              this.folderId=''
            }
            
            this.tabelData.forEach(item=>{
              item.ischecked=false
            })

            this.$nextTick(()=>{
              console.log(oldfolderId)
              if(oldfolderId){
                var dom = document.querySelector(".each-floder[folderId='"+oldfolderId+"']")
                if(dom){
                  dom.click()
                }
                this.$store.commit("setPageParam",{})
              }
              // var dom1=document.createElement("img")
              // dom1.src=require('../../assets/image/down-up.png')
              // $(".sortTime .caret-wrapper").html(dom1)
            })
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    clearTaskFile(){
      this.$refs.taskFile.clearAll()
    }
  },
};
</script>
<style type="text/css" scoped>
.floder-div{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.foldertableLimit >>>.el-table__body-wrapper {
    height: calc(100% - 58px)!important;
    overflow: auto;
}
.foldertableLimit >>>th{
  font-weight: 500;
  color: #313131;
}
.searchUser >>>.el-input__inner{
  border-radius: 20px;
}
.folder-icon{
  width: 28px;
  height: 24px;
  margin-right: 15px;
}
.btn-img.addtask {
    width: 13px;
    height: 13px;
}
img.btn-img {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}
.checkeone .btn-img{
    width: 17px;
    height: 18px;
}
.createCgon>>>span {
    display: flex;
    align-items: center;
}
.header-do {
    height: 50px;
    border-bottom: 2px solid #eee;
    display: flex;
    align-items: center;
}
.createCgon {
    margin-left: 34px;
    font-size: 13px;
}

.hedat-contnt {
    font-size: 15px;
    font-weight: 600;
}
.candode {
    margin-top: 12px;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 96%;
    height: 40px;
    margin: 0;
    justify-content: space-between;
}
.caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}
.sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
}
.sort-caret.ascending {
    border-bottom-color: #C0C4CC;
    top: 5px;
}
.sort-caret.descending {
    border-top-color: #C0C4CC;
    bottom: 7px;
}
.sort-caret.ascending.on{
  border-bottom-color: #409EFF;
}
.sort-caret.descending.on {
    border-top-color: #409EFF;
    bottom: 7px;
}
.createAll /deep/.el-input__inner {
    height: 31.6px;
    line-height: 31.6px;
    border: none;
    border-top: 1px solid #ccc;
    border-radius: 0;
}
.createAll /deep/.el-select__caret.el-input__icon.el-icon-arrow-up {
    line-height: 30px;
}
.createAll {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.taskCreate {
    display: flex;
    align-items: center;
}
.heights{
  width: 100%;
  height: calc(100% - 50px);
}
.type-task {
    width: 97px;
    background: rgb(233 247 250);
    border: 1px solid rgb(59 222 255);
    line-height: 30px;
    font-size: 13px;
    margin-right: 15px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
    width: 100%;
}
.el-date-editor /deep/.el-range-separator {
    width: 25px;
}
.taskicon{
  font-size: 17px;
  color: rgb(143 134 119);
  margin-right: 4px;
  margin-left: 4px;
}
.floder-icon{
  color: rgb(255,191,0);
}
.container {
  width: 100%;
  height: 100%;
}
.el-main {
  padding: 0;
  /* height: calc(100% - 40px); */
}
.el-container {
  height: 100%;
}
.floders-container.floders {
    height: calc(100% - 70px);
    width: 100%;
    padding: 0 0 20px;
    margin-top: 8px;
}
.sort-folder {
    display: flex;
    align-items: center;
    height: 50px;
    background: #f3f3f3;
}
.sort-method {
    width: 110px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}
.sort-method.on{
  background: #fff;
    color: #409EFF;
    border: 1px solid #eee;
}
.flodersList {
    height: calc(100%);
    overflow: auto;
    width: 100%;
}
.floder-each {
    padding-top: 15px;
}
.floder-each>div {
    width: 183px;
    height: 88px;
    float: left;
    border: 1px solid #e7e7e7;
    font-size: 13px;
    color: #818181;
    cursor: pointer;
    margin-top: 10px;
}
.floder-each>div:first-child {
    line-height: 88px;
    background: #fff;
    font-size: 14px;
}
.pictureNum {
    font-size: 12px;
    margin-left: 0px;
    padding-top: 2px;
    text-align: left;
    color: #484848;
}
.createTime {
    font-size: 12px;
    width: 100%;
    text-align: left;
    padding-left: 6px;
    padding-top: 3px;
    color: #484848;
}
.floder-each>.each-floder {
  position: relative;
  background: rgb(247,249,250);
}
.each-content {
    height: 73%;
    background: rgb(241,243,246);
    text-align: left;
    padding: 6px 3px 5px 6px;
}
.floder-name {
    color: #333333;
    padding-left: 6px;
    font-size: 14px;
}
.floder-introduce {
    padding-top: 3px;
    padding-left: 10px;
    font-size: 12px;
}

.el-date-editor .el-range-separator {
  width: 8% !important;
}
.container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.el-container::-webkit-scrollbar-track {
  background-color: #545c64;
}
.content-do {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  box-sizing: border-box;
}
.el-table /deep/.caret-wrapper{
  justify-content: center;
}
.el-table /deep/.caret-wrapper img{
  width: 8px;
  height: 20px;
}
</style>
